import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Outlet } from 'react-router-dom';

const App = (): JSX.Element => {
  return (
    <div className='theme-light'>
      <Outlet />
      <div id='modal-root' />
    </div>
  );
};

const launchdarklyContext = localStorage.getItem('launchdarkly.context');

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY ?? '',
  context: JSON.parse(launchdarklyContext ?? '{}'),
  options: { streaming: false },
})(App);
