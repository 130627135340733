import { useNavigate, useRouteError } from 'react-router-dom';
import { ContainedButton } from '@/modules/components/form/Buttons';

const Error = (): JSX.Element => {
  const error = useRouteError() as any;
  const navigate = useNavigate();

  return (
    <>
      <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <p className='text-base font-semibold text-indigo-600'>{error.status}</p>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>Page not found</h1>
          <p className='mt-6 text-base leading-7 text-gray-600'>Sorry, we couldn’t find the page you’re looking for.</p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <ContainedButton
              className='min-w-fit'
              onClick={() => {
                navigate('/');
              }}
            >
              Go back home
            </ContainedButton>
          </div>
        </div>
      </main>
    </>
  );
};

export default Error;
