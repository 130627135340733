import TextInput from '@/modules/components/form/TextInput';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import reduce from 'lodash/reduce';
import { isEmpty } from '@/common';
import classNames from 'classnames';

interface DayIntervalProps {
  targetIndex: number;
  day: string;
  intervals: string[];
}

const EachHourOfDay = (props: DayIntervalProps): JSX.Element => {
  const { day, intervals = [], targetIndex } = props;
  const { register, watch } = useFormContext();
  const watchOffset = watch(`targets.${targetIndex}.adjustedInterval.${day}.percent`) as number;
  const intervalValue = reduce(
    intervals,
    (acc, interval) => {
      acc[`targets.${targetIndex}.adjustedInterval.${day}.${interval}`] = {
        value: watch(`targets.${targetIndex}.interval.${day}.${interval}`) as number,
        adjustedValue: watch(`targets.${targetIndex}.adjustedInterval.${day}.${interval}`) as number,
      };
      return acc;
    },
    {} as Record<string, { value: number; adjustedValue: number }>,
  );

  const getTextColor = useCallback(
    (path: string) => {
      const percent = isEmpty(watchOffset) ? 0 : watchOffset / 100;
      const { value, adjustedValue } = intervalValue[path];

      // Base value
      if (value === adjustedValue) {
        return 'text-emphasis-tertiary';
      }

      // Adjusted at daily level
      if (adjustedValue === Math.round(value * (1 + percent))) {
        return 'text-status-info';
      }

      // Manually adjusted at interval level
      return 'text-emphasis-adjusted';
    },
    [intervalValue, watchOffset],
  );

  return (
    <div className='flex items-center space-x-6'>
      {intervals.map((interval) => {
        const textColor = getTextColor(`targets.${targetIndex}.adjustedInterval.${day}.${interval}`);

        return (
          <div key={interval}>
            <TextInput
              type='number'
              min={0}
              label={interval}
              className={classNames('ml-2 w-16 text-center font-medium', textColor)}
              inline
              {...register(`targets.${targetIndex}.adjustedInterval.${day}.${interval}`, {
                valueAsNumber: true,
              })}
            />
            <input
              hidden
              {...register(`targets.${targetIndex}.interval.${day}.${interval}`, {
                valueAsNumber: true,
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EachHourOfDay;
