import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Children } from 'react';

interface FeatureProps {
  flag: FeatureFlags;
  children: JSX.Element | JSX.Element[];
}

const Feature = (props: FeatureProps): JSX.Element => {
  const { flag, children } = props;
  const { [flag]: flagValue = false } = useFlags();

  let on, off;

  /* Determine if the children is On or Off
    Sample use case
    <Feature flag={FeatureFlags.eyecueAdminUseSyncHistoricalData}>
      <Feature.On>
        ...
      </Feature.On>
      <Feature.Off>
        ...
      </Feature.Off>
    </Feature>
  */
  Children.forEach(children, (child) => {
    if (child.type === On) {
      return (on = child);
    }

    if (child.type === Off) {
      return (off = child);
    }
  });

  return <>{flagValue ? on ?? children : off ?? <></>}</>;
};

const On = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => <>{props.children}</>;
const Off = (props: { children: JSX.Element | JSX.Element[] }): JSX.Element => <>{props.children}</>;

Feature.On = On;
Feature.Off = Off;

export default Feature;
