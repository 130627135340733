import React from 'react';
import classNames from 'classnames';

export interface DialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export interface DialogWrapperProps extends DialogProps {
  children: JSX.Element | JSX.Element[];
}

const DialogWrapper = (props: DialogWrapperProps): JSX.Element => {
  const { open, setOpen } = props;

  return (
    <div
      className={classNames(
        open ? 'flex' : 'hidden',
        'flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full',
      )}
    >
      <div
        className={classNames('fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity block')}
        onClick={() => {
          setOpen(false);
        }}
      />
      <div className={classNames('relative p-4 w-fit max-h-full')}>
        <div className={classNames('relative bg-white rounded-lg shadow')}>{props.children}</div>
      </div>
    </div>
  );
};

export const ModalHeader = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <div className={classNames('flex h-16 items-center px-6 bg-white shadow sticky rounded-t-lg top-0 z-10')}>{children}</div>;
};

export const ModalTitle = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  return <span className={classNames('text-2xl font-light text-emphasis-secondary')}>{children}</span>;
};

export const ModalContent = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <div className={classNames('bg-white px-4 py-5 rounded-b-lg')}>{children}</div>;
};

export interface CloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const CloseButton = (props: CloseButtonProps): JSX.Element => {
  return (
    <button
      type='button'
      className={classNames('bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center text-emphasis-primary')}
      data-modal-hide='default-modal'
      {...props}
    >
      <svg className={classNames('w-3 h-3')} aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
          className={classNames('fill-emphasis-primary')}
        />
      </svg>
      <span className='sr-only'>Close</span>
    </button>
  );
};

export default DialogWrapper;
