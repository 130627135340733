import { calculateAdjustedValue, forEachIntervalByDay, getEachMinuteIntervalForEachHourAndDay } from './utils';
import React, { useCallback, useMemo } from 'react';
import { SyncIcon } from '@/common/assets/icons';
import { DayOfWeek, setLastExpandedDay, getHistoricalData as getHistoricalDataAction } from '@/stores/_stores/targetConfigs';
import CsvParser from '@/modules/settings/targets/interval/CsvParser';
import { isEmpty, useAppDispatch, useAppSelector, useCurrentStore } from '@/common';
import { Controller, useFormContext } from 'react-hook-form';
import { ContainedButton } from '@/modules/components/form/Buttons';
import get from 'lodash/get';
import Feature from '@/modules/components/feature';
import { FeatureFlags } from '@/types';
import EachDayOfWeek from '@/modules/settings/targets/interval/EachDayOfWeek';
import confirmSyncOption from '@/modules/settings/targets/interval/SyncOptionConfirm';
import { formatInTimeZone } from 'date-fns-tz';
import { selectStoreTimeZone } from '@/stores/_stores/generalConfigs';
import { selectClientConfig } from '@/stores/clientConfigs';

interface TargetIntervalProps {
  targetIndex: number;
  openingHour?: number;
  closingHour?: number;
  step?: number;
}

const TargetInterval = (props: TargetIntervalProps): JSX.Element => {
  const { openingHour = 6, closingHour = 23, step = 15, targetIndex } = props;
  const dispatch = useAppDispatch();
  const currentStore = useCurrentStore();
  const eachDayInterval: Record<DayOfWeek, Record<number, string[]>> = useMemo(
    () => getEachMinuteIntervalForEachHourAndDay(openingHour, closingHour, { step, format: 'HH:mm' }),
    [openingHour, closingHour, step],
  );
  const dayOfWeek = useMemo(() => Object.keys(eachDayInterval), [eachDayInterval]);
  const { setValue, getValues, control } = useFormContext();
  const timezone = useAppSelector(selectStoreTimeZone);
  const clientConfig = useAppSelector(selectClientConfig);

  // This callback will be called when the csv file is parsed or historical data is synced
  // 1. Prompt the user to confirm the sync options
  // 2. Update the interval value for each day (baseline value)
  // 3. Apply sync options and calculate the adjusted value
  const onParsed = useCallback(
    async (data: Record<string, Record<string, number>>): Promise<void> => {
      const syncOptions = await confirmSyncOption();

      if (!syncOptions) {
        return;
      }

      const { keepPercentage, keepOverrides } = syncOptions;

      dayOfWeek.forEach((day) => {
        const baselineDayPath = `targets.${targetIndex}.interval.${day}`;
        const adjustedDayPath = `targets.${targetIndex}.adjustedInterval.${day}`;

        const percentage = getValues(`${adjustedDayPath}.percent`);

        if (!keepPercentage) {
          setValue(`${adjustedDayPath}.percent`, null, { shouldDirty: true });
        }

        forEachIntervalByDay(eachDayInterval[day as DayOfWeek], (interval) => {
          const newBaseline = get(data, [day, interval], null);
          const currentBaseline = getValues(`${baselineDayPath}.${interval}`);
          const currentAdjustedValue = getValues(`${adjustedDayPath}.${interval}`);

          const newAdjustedValue = calculateAdjustedValue(newBaseline, currentBaseline, currentAdjustedValue, percentage, keepPercentage, keepOverrides);

          setValue(`${baselineDayPath}.${interval}`, newBaseline, { shouldDirty: true });
          setValue(`${adjustedDayPath}.${interval}`, newAdjustedValue, { shouldDirty: true });
        });
      });

      // Expand the first day
      dispatch(setLastExpandedDay({ day: dayOfWeek[0], targetIndex }));
    },
    [dayOfWeek, dispatch, eachDayInterval, setValue, targetIndex],
  );

  const getHistoricalData = useCallback(async (): Promise<void> => {
    if (currentStore !== undefined) {
      // Get historical data
      const data = await dispatch(getHistoricalDataAction({ clientId: clientConfig?.id ?? '', storeId: currentStore.storeId })).unwrap();

      if (data !== null) {
        onParsed(data);
      } else {
        window.logger.error(`Failed to get historical data for store ${currentStore.storeId}`);
      }
    }
  }, [currentStore, dispatch, onParsed]);

  return (
    <>
      <span className='font-medium text-emphasis-primary'>Departure count</span>
      <div className='flex items-center space-x-20'>
        <label>Target csv file</label>
        <Feature flag={FeatureFlags.eyecueAdminUseSyncHistoricalData}>
          <Feature.On>
            <ContainedButton
              className='min-w-fit'
              onClick={(e) => {
                e.preventDefault();
                void getHistoricalData();
              }}
            >
              <SyncIcon className='fill-emphasis-overlay' />
              Sync historical data
            </ContainedButton>
          </Feature.On>
          <Feature.Off>
            <CsvParser onParsed={onParsed} />
          </Feature.Off>
        </Feature>
      </div>
      <div className='flex items-center space-x-20'>
        <label>Latest change:</label>
        <Controller
          control={control}
          name={`targets.${targetIndex}.lastUpdated`}
          render={({ field: { value } }) => <span>{isEmpty(value) ? '' : formatInTimeZone(new Date(value), timezone, 'h.mmaaa dd/MM/yy')}</span>}
        />
      </div>
      {/* Day of week */}
      <ul role='list' className='divide-y divide-divider-muted'>
        {/* Use empty li element to add a divider above Monday */}
        <li></li>
        {dayOfWeek.map((day) => (
          <li key={day} className='relative flex justify-between gap-x-6 pr-4 py-2'>
            <EachDayOfWeek targetIndex={targetIndex} day={day as DayOfWeek} dayIntervals={eachDayInterval[day as DayOfWeek]} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default TargetInterval;
