import Popper, { type PopperProps } from '.';
import React from 'react';

interface ErrorPopperProps extends PopperProps {}

const ErrorPopper = (props: ErrorPopperProps): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Popper className='p-6 bg-status-error-1 text-emphasis-overlay' {...rest}>
      {children}
    </Popper>
  );
};

export default ErrorPopper;
