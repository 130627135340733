import { useAuth } from '@/common';
import Spinner from '@/modules/components/spinner';
import { useEffect } from 'react';

const Logout = (): JSX.Element => {
  const { logout } = useAuth();

  useEffect(() => {
    void logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return (
    <>
      <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <Spinner message='Logging out...' />
          <p className='mt-6 text-base leading-7 text-gray-600'>Logging out...</p>
        </div>
      </main>
    </>
  );
};

export default Logout;
