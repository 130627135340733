import { useAppSelector } from '@/common';
import { NavLink } from 'react-router-dom';
import { selectDashboardTemplates } from '@/stores/dashboardTemplates';

const DashboardTemplate = (): JSX.Element => {
  const templates = useAppSelector(selectDashboardTemplates);

  return (
    <ul role='list' className='divide-y divide-divider-light'>
      {templates.map((template) => (
        <li key={template.id} className='relative flex items-center justify-between gap-x-6 px-[10px] py-6'>
          <NavLink to={`${template.id}/preview?heightOffset=170px`} className='flex min-w-0 gap-x-4 cursor-pointer'>
            <div className='grid grid-cols-2 gap-x-48 items-center min-w-0 flex-auto text-base font-normal leading-6 text-emphasis-secondary'>
              <p className='leading-6 min-w-80'>{template.name}</p>
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default DashboardTemplate;
