import React, { type ChangeEvent, useRef, useState } from 'react';
import Papa from 'papaparse';
import reduce from 'lodash/reduce';
import { isEmpty } from '@/common';
import capitalize from 'lodash/capitalize';
import { DayOfWeek } from '@/stores/_stores/targetConfigs';
import { ContainedButton } from '@/modules/components/form/Buttons';
import ErrorPopper from '@/modules/components/popper/ErrorPopper';

/*
input: Array<Record<string, string>>
[
  {
    day_name: 'Monday',
    '06:30': '6',
    '06:45': '9',
    '07:00': '7',
  },
  {
    day_name: 'Tuesday',
    '06:30': '8',
    '06:45': '10',
    '07:00': '6',
  }
];

output: { result: Record<string, Record<string, string>>; errors: string[] }
{
  result: {
    monday: {
      '06:30': '6',
      '06:45': '9',
      '07:00': '7',
    },
    tuesday: {
      '06:30': '8',
      '06:45': '10',
      '07:00': '6',
    },
  },
  errors: [],
},
*/
export const parseCsvTarget = (data: Array<Record<string, string | undefined>>): { result: Record<string, Record<string, number>>; errors: string[] } => {
  const timePattern = /(\d{1,2}):(\d{1,2})/;
  const validDays = Object.values(DayOfWeek).map((day) => day.toLowerCase());
  const errors = [] as string[];

  const result = reduce(
    data,
    (result, value) => {
      const { day_name: dow, ...intervals } = value;

      // Validate day of week
      if (isEmpty(dow) || dow === undefined || !validDays.includes(dow.toLowerCase())) {
        errors.push(`Invalid day of week. Found '${dow}'. Days of week must be ${validDays.map((d) => capitalize(d)).join(', ')}`);
        return result;
      }

      // Process and validate intervals time and target
      result[dow.toLowerCase()] = reduce(
        intervals,
        (acc, target, time) => {
          if (isEmpty(target) || isNaN(Number(target)) || Number(target) < 0) {
            errors.push(`Invalid target found on ${dow} at ${time}: '${target}'`);
            return acc;
          }

          if (isEmpty(time) || !timePattern.test(time)) {
            errors.push(`Invalid time found on ${dow}: '${time}'`);
            return acc;
          }

          acc[time] = Number(target);

          return acc;
        },
        {} as Record<string, number>,
      );

      return result;
    },
    {} as Record<string, Record<string, number>>,
  );

  return {
    result,
    errors,
  };
};

type DayData = Record<string, number>;

type FormattedData = Record<string, DayData>;

// Possible values of first column of the 'quicksight' format for target csvs.
enum WeekNameAndNumberDayOfWeek {
  Monday = '1 Monday',
  Tuesday = '2 Tuesday',
  Wednesday = '3 Wednesday',
  Thursday = '4 Thursday',
  Friday = '5 Friday',
  Saturday = '6 Saturday',
}

/*
  input: Array<string, string, string>
  [
    ['1 Monday     ', '06:00:00', '6.25'],
    ['1 Monday     ', '06:15:00', '4.75'],
    ...
    ['2 Tuesday     ', '06:00:00', '4.75'],
    ...
    ['6 Saturday     ', '21:45:00', '34']
  ]

  output: { result: Record<string, Record<string, string>>; errors: string[] }
  {
    result: {
      monday: {
        '06:30': '6',
        '06:45': '9',
        '07:00': '7',
      },
      tuesday: {
        '06:30': '8',
        '06:45': '10',
        '07:00': '6',
      },
    },
    errors: [],
  }
*/
export const parseQuicksightCsvTarget = (data: Array<[string, string, string]>): { result: Record<string, Record<string, number>>; errors: string[] } => {
  const timePattern = /(\d{1,2}):(\d{1,2}):(\d{1,2})/;
  const validWeekNameAndNumbers = Object.values(WeekNameAndNumberDayOfWeek);

  const errors: string[] = [];

  const result = data.reduce<FormattedData>((acc, [dowAndNumber, time, target]) => {
    const rowErrors = [];
    // Validate day of week
    if (isEmpty(dowAndNumber) || dowAndNumber === undefined || !validWeekNameAndNumbers.includes(dowAndNumber.trim() as WeekNameAndNumberDayOfWeek)) {
      rowErrors.push(`Invalid day of week. Found '${dowAndNumber}'. Days of week must be ${validWeekNameAndNumbers.join(', ')}`);
    }

    // Validate time
    if (isEmpty(time) || !timePattern.test(time)) {
      rowErrors.push(`Invalid time found on ${dowAndNumber}: '${time}'`);
    }

    // Validate target
    if (isEmpty(target) || isNaN(Number(target)) || Number(target) < 0) {
      rowErrors.push(`Invalid target found on ${dowAndNumber} at ${time}: '${target}'`);
    }

    if (rowErrors.length > 0) {
      errors.push(...rowErrors);
      return acc;
    }

    const formattedDay = dowAndNumber.trim().split(' ')[1].toLowerCase();
    const formattedTime = time.slice(0, 5);
    const formattedTarget = Math.round(parseFloat(target));

    if (acc[formattedDay] === undefined) {
      acc[formattedDay] = {};
    }

    acc[formattedDay][formattedTime] = formattedTarget;
    return acc;
  }, {});

  return { result, errors };
};

const readFileAsText = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Error reading file'));
    };

    reader.readAsText(file);
  });
};

const CsvParser = (props: { onParsed?: (data: Record<string, Record<string, number>>) => void }): JSX.Element => {
  const { onParsed } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileNameSpanRef = useRef<HTMLSpanElement>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = e.target;

    if (files === null || files.length === 0) {
      window.logger.error('No file selected');
      return;
    }

    const file = files[0];

    if (file.type !== 'text/csv') {
      window.logger.error('Invalid file type');
      return;
    }

    setSelectedFile(file);

    // Reset errors
    setErrors([]);

    // Determine if this file comes from quicksight (i.e, the header includes the line, 'weeknameandnumber'), or if it's the sparsely populated format used by eyecue
    const fileContent = await readFileAsText(file);
    const isCsvFromQuicksight = fileContent.includes('weeknameandnumber');

    Papa.parse(file, {
      complete: (results): void => {
        // parse from results.data.slice(1, -1) to remove the header line, and the last empty line
        const { result, errors } = isCsvFromQuicksight
          ? parseQuicksightCsvTarget(results.data.slice(1, -1) as Array<[string, string, string]>)
          : parseCsvTarget(results.data as Array<Record<string, string | undefined>>);

        if (errors.length === 0 && onParsed !== undefined) {
          onParsed(result);
        }

        setErrors(errors);
      },
      header: !isCsvFromQuicksight, // If the csv is from quicksight, the header is unhelpful (uses space " " characters for two column labels), so just read in as an array of 3 element tuples
    });
  };

  return (
    <div className='text-base font-normal text-emphasis-muted'>
      <ContainedButton
        className='w-48'
        onClick={(e) => {
          e.preventDefault();
          if (fileInputRef.current !== null) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
          }
        }}
      >
        Browse to upload file
      </ContainedButton>
      {selectedFile !== null && (
        <span ref={fileNameSpanRef} className='ml-4 font-medium text-emphasis-secondary text-base leading-6'>
          {selectedFile.name}
        </span>
      )}
      <input ref={fileInputRef} type='file' accept='.csv' onChange={handleChange} hidden />
      <ErrorPopper anchorElement={fileNameSpanRef.current} visible={errors.length > 0}>
        <div className='space-y-1.5'>
          <p>Sorry there is a problem with CSV file.</p>
          <p>Please fix the following and try uploading again.</p>
          <ul role='list' className='list-decimal space-y-1 pl-5'>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      </ErrorPopper>
    </div>
  );
};

export default CsvParser;
