import React, { useCallback, useMemo } from 'react';
import { ChevronRightIcon, ChevronDownIcon, DotIcon, RevertIcon } from '@/common/assets/icons';
import { type DayOfWeek, selectLastExpandedDay, setLastExpandedDay } from '@/stores/_stores/targetConfigs';
import map from 'lodash/map';
import EachHourOfDay from './EachHourOfDay';
import capitalize from 'lodash/capitalize';
import { isEmpty, useAppDispatch, useAppSelector, useCurrentStore } from '@/common';
import { useFormContext } from 'react-hook-form';
import TextInput from '@/modules/components/form/TextInput';
import classNames from 'classnames';
import { selectClientConfig } from '@/stores/clientConfigs';
import { TextButton } from '@/modules/components/form/Buttons';
import get from 'lodash/get';
import { forEachIntervalByDay } from '@/modules/settings/targets/interval/utils';
import confirm from '@/modules/components/confirmDialog/ConfirmDialog';
import { revertToBaseline as revertToBaselineAction } from '@/stores/_stores/actions';

interface EachDayOfWeekProps {
  targetIndex: number;
  day: DayOfWeek;
  dayIntervals: Record<number, string[]>;
}

const EachDayOfWeek = (props: EachDayOfWeekProps): JSX.Element => {
  const { day, dayIntervals, targetIndex } = props;
  const dispatch = useAppDispatch();
  const currentStore = useCurrentStore();
  const clientConfig = useAppSelector(selectClientConfig);
  const lastExpandedDay = useAppSelector(selectLastExpandedDay);
  const expandedDay = useMemo(() => (lastExpandedDay?.targetIndex === targetIndex ? lastExpandedDay.day : ''), [lastExpandedDay, targetIndex]);
  const { getValues, setValue, register } = useFormContext();

  const onPercentChange = useCallback(
    (percent: number) => {
      forEachIntervalByDay(dayIntervals, (interval) => {
        const [value] = getValues([`targets.${targetIndex}.interval.${day}.${interval}`]); // Get based value
        if (value !== null) {
          setValue(`targets.${targetIndex}.adjustedInterval.${day}.${interval}`, Math.round(value * (1 + percent)), {
            shouldDirty: true,
          });
        }
      });
    },
    [day, dayIntervals, getValues, setValue, targetIndex],
  );

  // "Revert to baseline" will revert all changes to baseline value for the day
  // It also acts as "Discard changes" button, but for a specific day
  const revertToBaseline = useCallback(async (): Promise<void> => {
    const isConfirmed = (await confirm({
      title: 'Revert to baseline',
      message: (
        <div className='grid grid-cols-1 gap-y-4'>
          <span className='flex justify-center'>Reverting to baseline will discard all target adjustments.</span>
          <span className='flex justify-center'>Do you want to do this?</span>
        </div>
      ),
    })) as boolean;

    if (!isConfirmed) {
      return;
    }

    if (currentStore !== undefined && clientConfig !== undefined) {
      const dayPath = `targets.${targetIndex}.adjustedInterval.${day}`;

      const intervalValue = (await dispatch(
        revertToBaselineAction({
          storeId: currentStore?.storeId,
          clientId: clientConfig?.id,
          dayPath,
        }),
      ).unwrap()) as Record<string, number>;

      // If the user make some changes for first time and click "Revert to baseline" without saving the changes, the form won't update to show baseline value.
      // Because technically the value is not changed in redux store, so react won't re-render the component.
      // We need to manually discard all unsaved changes for this day
      setValue(`${dayPath}.percent`, null, { shouldDirty: false });
      forEachIntervalByDay(dayIntervals, (interval) => {
        const value = get(intervalValue, `${interval}`, null);
        setValue(`${dayPath}.${interval}`, value, { shouldDirty: false });
      });
    } else {
      window.logger.error(`Failed to revert to baseline for store ${currentStore?.storeId} & client ${clientConfig?.id}`);
    }
  }, [clientConfig, currentStore, day, dayIntervals, dispatch, setValue, targetIndex]);

  return (
    <div className='flex items-center min-w-0 gap-x-4'>
      <div className='min-w-0'>
        <button
          type='button'
          className='inline-flex items-center gap-x-1.5 py-1.5'
          onClick={() => {
            dispatch(setLastExpandedDay({ day: expandedDay === day ? '' : day, targetIndex }));
          }}
        >
          {expandedDay === day ? <ChevronDownIcon /> : <ChevronRightIcon />}
          <p className='text-base font-normal leading-6 text-emphasis-secondary'>{capitalize(day)}</p>
        </button>

        <div className={classNames({ hidden: expandedDay !== day })}>
          {/* % adjustment */}
          <div className='flex items-center pt-4 pb-5'>
            <TextInput
              type='number'
              min={-100}
              label='Adjust daily target'
              className='ml-14 w-16 text-center text-emphasis-loud font-medium'
              inline
              {...register(`targets.${targetIndex}.adjustedInterval.${day}.percent`, {
                valueAsNumber: true,
                onChange: (e) => {
                  const percent = isEmpty(e.target.value) ? 0 : e.target.value / 100;
                  onPercentChange(percent);
                },
              })}
            />
            <span className='ml-2'>%</span>
            <TextButton
              className='ml-20'
              onClick={(e) => {
                e.preventDefault();
                void revertToBaseline();
              }}
            >
              <RevertIcon className='fill-emphasis-primary' />
              Revert to baseline
            </TextButton>
          </div>

          {/* Legend */}
          <div className='flex items-center gap-x-8 pt-4 pb-10'>
            <span className='flex items-center gap-x-4'>
              <DotIcon className='fill-emphasis-tertiary' /> <span className='text-emphasis-tertiary'>Baseline</span>
            </span>
            <span className='flex items-center gap-x-4'>
              <DotIcon className='fill-status-info' /> <span className='text-status-info'>Adjusted %</span>
            </span>
            <span className='flex items-center gap-x-4'>
              <DotIcon className='fill-emphasis-adjusted' /> <span className='text-emphasis-adjusted'>Override</span>
            </span>
          </div>

          {/* Interval of day */}
          <ul role='list' className='my-2'>
            {map(dayIntervals, (intervals, hour) => (
              <li key={`hour-${hour}`} className='relative flex justify-between gap-x-6 pr-4 py-2 cursor-pointer'>
                <EachHourOfDay day={day} intervals={intervals} targetIndex={targetIndex} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EachDayOfWeek;
