import React, { type Ref } from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element | JSX.Element[];
}

const Checkbox = (props: CheckboxProps, ref: Ref<HTMLInputElement>): JSX.Element => {
  const { id, label, ...rest } = props;

  return (
    <div className='relative flex items-start'>
      <div className='flex h-6 items-center'>
        <input
          ref={ref}
          id={id}
          type='checkbox'
          className='h-4 w-4 rounded border-emphasis-secondary text-emphasis-primary focus:ring-emphasis-primary'
          {...rest}
        />
      </div>
      <div className='ml-3 text-base leading-6'>
        <label htmlFor={id} className='font-normal text-emphasis-secondary'>
          {label}
        </label>
      </div>
    </div>
  );
};

export default React.forwardRef(Checkbox);
