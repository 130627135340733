import { createBrowserRouter, createRoutesFromElements, LoaderFunctionArgs, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';
import Error from '@/pages/Error';
import Logout from '@/pages/Logout';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch } from '@/common';
import { getUserStores } from '@/stores/clientConfigs';
import Store from '@/modules/stores';
import Device from '@/modules/devices';
import DashboardAdmin from '@/modules/dashboards';
import ConfiguredDashboards from '@/modules/dashboards/configuredDashboards';
import DashboardPreview from '@/modules/dashboards/dashboardPreview';
import Monitor from '@/modules/monitor';
import { getDevices } from '@/stores/devices';
import { getDashboards } from '@/stores/dashboards';
import Dashboard from '@/modules/dashboards/dashboard';
import DesktopMasterLayout from '@/layouts/DesktopMasterLayout';
import FullScreenLayout from '@/layouts/FullScreenLayout';
import App from '@/App';
import TargetSetting from '@/modules/settings/targets';
import Reports from '@/modules/reports';
import Setting from '@/modules/settings';
import React, { useContext, useMemo } from 'react';
import { ApplicationContext } from '@/types';
import { getStoreData } from '@/stores/_stores/actions';
import DashboardTemplate from '@/modules/dashboards/dashboardTemplates';
import { getDashboardTemplates } from '@/stores/dashboardTemplates';
import TemplatePreview from '@/modules/dashboards/templatePreview';

const AppRouter = (): JSX.Element => {
  const applicationContext = useContext(ApplicationContext);
  const dispatch = useAppDispatch();

  const { clientId, allowedStoreIds } = useMemo(() => {
    return {
      clientId: applicationContext.organization ?? '',
      allowedStoreIds: applicationContext.allowedStoreIds?.split(',') ?? [],
    };
  }, [applicationContext]);

  // Generic function to dispatch an action with path params
  const dispatchActions =
    (...actions: Array<AsyncThunk<any, any, any>>) =>
    async ({ params }: LoaderFunctionArgs<any>): Promise<number> => {
      actions.forEach((action) => {
        dispatch(action({ ...params }));
      });

      return 0;
    };

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route path='/' element={<App />}>
            <Route path='logout' element={<Logout />} />
            <Route path=':storeId/dashboards/all/:dashboardId/live/*' element={<FullScreenLayout />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route
              path='/'
              element={<DesktopMasterLayout />}
              errorElement={<Error />}
              loader={async (): Promise<number> => {
                dispatch(getUserStores({ organization: clientId, allowedStoreIds }));
                return 0;
              }}
            >
              <Route index element={<Navigate to='stores' />} />
              <Route path='stores' element={<Store />} />
              <Route path=':storeId' element={<Outlet />}>
                <Route path='reports' element={<Reports />} />
                <Route path='screens' element={<Device />} loader={dispatchActions(getDevices, getDashboards)} />
                <Route path='dashboards' element={<DashboardAdmin />}>
                  <Route index element={<Navigate to='all' />} />
                  <Route path='all' element={<Outlet />}>
                    <Route index element={<ConfiguredDashboards />} loader={dispatchActions(getDashboards)} />
                    <Route path=':dashboardId/preview/*' element={<DashboardPreview />} />
                  </Route>
                  <Route path='templates' element={<Outlet />}>
                    <Route
                      index
                      element={<DashboardTemplate />}
                      loader={async (): Promise<number> => {
                        dispatch(getDashboardTemplates({ clientId }));
                        return 0;
                      }}
                    />
                    <Route path=':templateId/preview/*' element={<TemplatePreview />} />
                  </Route>
                </Route>
                <Route path='schedules' element='schedules' />
                <Route path='messaging' element='messaging' />
                <Route path='monitor/*' element={<Monitor />} />
                <Route path='settings' element={<Setting />}>
                  <Route index element={<Navigate to='targets' />} />
                  <Route
                    path='targets'
                    element={<TargetSetting />}
                    loader={async ({ params }): Promise<number> => {
                      dispatch(getStoreData({ storeId: params.storeId ?? '', clientId }));
                      return 0;
                    }}
                  />
                </Route>
              </Route>
              <Route path='help' element='help' />
            </Route>
          </Route>,
        ),
        { basename: '/admin' },
      ),
    [clientId, allowedStoreIds, dispatch],
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
