import React from 'react';
import createConfirmDialog, { type ConfirmDialogProps } from '@/modules/components/confirmDialog';
import Checkbox from '@/modules/components/form/Checkbox';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import OptionConfirmDialog from '@/modules/components/confirmDialog/OptionConfirmDialog';

interface SyncOptions {
  keepPercentage: boolean;
  keepOverrides: boolean;
}

const SyncOptionConfirm = (props: ConfirmDialogProps): JSX.Element => {
  const methods = useForm<SyncOptions>({
    defaultValues: {
      keepPercentage: false,
      keepOverrides: false,
    },
  });

  return (
    <FormProvider {...methods}>
      <OptionConfirmDialog title='Update baseline data' {...props}>
        <span>New data may change existing settings</span>
        <Controller
          control={methods.control}
          name='keepPercentage'
          render={({ field: { onChange, value } }) => (
            <Checkbox id='keep-percentage' label='Keep and apply percentage adjustments' onChange={onChange} checked={value} />
          )}
        />
        <Controller
          control={methods.control}
          name='keepOverrides'
          render={({ field: { onChange, value } }) => (
            <Checkbox
              id='keep-override'
              label={
                <span>
                  Keep manual overrides <br /> (new baseline data may exceed existing manual overrides)
                </span>
              }
              onChange={onChange}
              checked={value}
            />
          )}
        />
      </OptionConfirmDialog>
    </FormProvider>
  );
};

export default createConfirmDialog(SyncOptionConfirm);
