import Header, { Title } from '@/modules/components/header';
import { useCurrentStore } from '@/common';
import { Navigation, NavigationItem } from '@/modules/components/navigation';
import { Outlet } from 'react-router-dom';

const Setting = (): JSX.Element => {
  const currentStore = useCurrentStore();

  return (
    <>
      <Header>
        <Title>{currentStore?.name ?? ''}</Title>
        <Navigation>
          <NavigationItem label='Global targets' name='setting-targets' to='.' />
        </Navigation>
      </Header>
      <Outlet />
    </>
  );
};

export default Setting;
