import ContentPanel from '@/modules/components/contentPanel';
import { selectIsTargetsLoading, selectStoreTarget } from '@/stores/_stores/targetConfigs';
import { useAppSelector } from '@/common';
import TargetForm from '@/modules/settings/targets/TargetForm';

const TargetSetting = (): JSX.Element => {
  const isLoading = useAppSelector(selectIsTargetsLoading);
  const storeTarget = useAppSelector(selectStoreTarget);

  return (
    <ContentPanel isLoading={isLoading} className='pl-12 pr-8 pt-6 pb-12'>
      {storeTarget !== undefined ? (
        <div className='flex gap-x-16'>
          <div className='w-56 mt-16'>
            <h2 className='text-lg font-semibold text-emphasis-primary pb-3'>Global targets</h2>
            <p className='text-sm font-normal text-emphasis-tertiary'>
              <span>These are general settings that will be used store wide.</span>
              <br />
              <span>Targets can be uploaded from a CSV file and then modified here.</span>
              <br />
              <span>Target adjustments can also be made globally.</span>
            </p>
          </div>
          <div className='flex-grow'>
            <TargetForm targets={storeTarget.targets} />
          </div>
        </div>
      ) : (
        <>No store config found</>
      )}
    </ContentPanel>
  );
};

export default TargetSetting;
