import { camelCase, isArray, map } from 'lodash';

// Ignore time pattern
// Example: 01:15 or 1:15
const timePattern = /(\d{1,2}):(\d{1,2})/;

interface Option {
  deep?: boolean;
  stopPaths?: string[];
}

const isObject = (value: any): boolean =>
  typeof value === 'object' && value !== null && !(value instanceof RegExp) && !(value instanceof Error) && !(value instanceof Date);

const mapObject = (input: any, fn: (key: string, value: any) => [string, any]): any => {
  if (!isObject(input)) return input;

  return Object.fromEntries(
    Object.entries(input).map(([k, v]) => {
      return fn(k, v);
    }),
  );
};

const toCamelCase = (input: any, option?: Option): any => {
  option = {
    deep: false,
    ...option,
  };

  const { stopPaths, deep = false } = option;
  const stopPathsSet = new Set(stopPaths);

  const makeMapper =
    (parentPath?: string) =>
    (key: string, value: any): [string, any] => {
      if (deep && isObject(value)) {
        const path = parentPath === undefined ? key : `${parentPath}.${key}`;

        if (!stopPathsSet.has(path)) {
          if (isArray(value)) {
            value = map(value, (el) => mapObject(el, makeMapper(path)));
          } else {
            value = mapObject(value, makeMapper(path));
          }
        }
      }

      if (!timePattern.test(key)) {
        key = camelCase(key);
      }

      return [key, value];
    };

  return mapObject(input, makeMapper(undefined));
};

export default function camelcaseKeys(input: any, option?: Option): any {
  if (isArray(input)) {
    return map(input, (el) => toCamelCase(el, option));
  }

  return toCamelCase(input, option);
}
