import createHttp from '@/services/http';
import { createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { parseError } from '../utils';
import { isEmpty } from '@/common';

export interface Device {
  serialNumber: string;
  storeId: string;
  name: string;
  theme: string;
  dashboard?: string;
}

const getDevicesQuery = `
  query getDevices($siteId: String, $serialNumber: String, $name: String){
    getDevices(siteId: $siteId, serialNumber: $serialNumber, name: $name) {
      storeId: siteId,
      serialNumber,
      name,
      theme,
      dashboard
    }
  }`;

const createDeviceQuery = `
  mutation createDevice($siteId: String, $serialNumber: String, $name: String, $theme: String, $dashboard: String){
    createDevice(siteId: $siteId, serialNumber: $serialNumber, name: $name, theme: $theme, dashboard: $dashboard) {
      storeId: siteId,
      serialNumber,
      name,
      theme,
      dashboard
    }
  }`;

const updateDeviceQuery = `
  mutation updateDevice($siteId: String, $serialNumber: String, $name: String, $theme: String, $dashboard: String){
    updateDevice(siteId: $siteId, serialNumber: $serialNumber, name: $name, theme: $theme, dashboard: $dashboard) {
      storeId: siteId,
      serialNumber,
      name,
      theme,
      dashboard
    }
  }`;

export const getDevices = createAsyncThunk<Device[], { storeId: string }>('devices/getDevices', async ({ storeId }): Promise<Device[]> => {
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: getDevicesQuery,
    variables: {
      siteId: storeId,
    },
  });

  const devices = get(response, 'data.data.getDevices', null);

  if (isEmpty(devices) || devices.length === 0) {
    window.logger.warn(`No devices found for site ${storeId}`);
    return [];
  }

  return devices;
});

export const createDevice = createAsyncThunk<Device | null, Device>(
  'devices/createDevice',
  async ({ storeId, serialNumber, name, theme, dashboard }, { rejectWithValue }): Promise<any> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: createDeviceQuery,
      variables: {
        siteId: storeId,
        serialNumber,
        name,
        theme,
        dashboard,
      },
    });

    const device = get(response, 'data.data.createDevice', null);

    if (device === null) {
      // Parse error messesage and reject with value
      window.logger.error(`Failed to create device for site ${storeId}`);
      const parsedError = parseError(response.data.errors);
      return rejectWithValue(parsedError);
    }

    return device;
  },
);

export const updateDevice = createAsyncThunk<
  Device | null,
  { storeId: string; serialNumber: string; params: { name?: string; theme?: string; dashboard?: string } }
>('devices/updateDevice', async ({ storeId, serialNumber, params }): Promise<any> => {
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: updateDeviceQuery,
    variables: {
      siteId: storeId,
      serialNumber,
      ...params,
    },
  });

  const device = get(response, 'data.data.updateDevice', null);

  if (device === null) {
    window.logger.error(`Failed to change theme for device ${serialNumber} for site ${storeId}`);
  }

  return device;
});
