import { useContext, useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import createHttp from '@/services/http';
import { isEmpty, useAppSelector } from '@/common';
import { get } from 'lodash';
import ContentPanel from '../components/contentPanel';
import Header, { Title } from '../components/header';
import { selectStores } from '@/stores/clientConfigs';
import { ApplicationContext } from '@/types';

const getQuicksightEmbeddingQuery = `
  query getQuicksightEmbedding($siteIds :[String], $organization:String){
    getQuicksightEmbedding(siteIds: $siteIds, organization: $organization ){
        url
    }
  }`;

const getQuicksightEmbedding = async (storeIds: string[], clientId: string): Promise<string | null> => {
  let truncatedStoreIds = [...storeIds];
  if (truncatedStoreIds.length > 10) {
    window.logger.warn(
      'More than 10 store ids for user. quicksightEmbedding does not currently support arbitrary number of store ids, so truncating to 10 store ids',
    );
    truncatedStoreIds = truncatedStoreIds.slice(0, 10);
  }
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: getQuicksightEmbeddingQuery,
    variables: {
      siteIds: truncatedStoreIds,
      organization: clientId,
    },
  });

  const errors = get(response, 'data.errors');
  if (errors && errors.length > 0) {
    window.logger.error('Errors on fetching quicksight embedding: ', JSON.stringify(errors));
    return null;
  }

  // Quicksight urls are good for one click only, after which they are invalidated as a security mesasure.
  // No point in putting in redux store, will need to be re-fetched each time quicksight is navigated to.
  const url = get(response, 'data.data.getQuicksightEmbedding.url', null);

  if (isEmpty(url)) {
    window.logger.warn(`No url found in response for request for site ids '${storeIds.join(', ')}' and clientId: '${clientId}'`);
    return null;
  }

  return url;
};

const Reports = (): JSX.Element => {
  const applicationContext = useContext(ApplicationContext);
  const organization = applicationContext.organization || '';
  const siteIds = useAppSelector(selectStores).map((store) => store.storeId);

  const [embeddingUrl, setEmbeddingUrl] = useState<string | null>('');

  useEffect(() => {
    const fetchEmbedding = async () => {
      const url = await getQuicksightEmbedding(siteIds, organization);
      setEmbeddingUrl(url);
    };
    fetchEmbedding();
  }, []);

  return (
    <>
      <Header>
        <Title>Reports</Title>
      </Header>
      <ContentPanel isLoading={embeddingUrl === ''}>
        <div className='h-screen w-full'>
          {embeddingUrl && <Iframe url={embeddingUrl} width='100%' height='100%' id='' className='' display='block' position='relative' />}
          {embeddingUrl == null && (
            <div className='relative h-screen'>
              <div className='absolute inset-x-0 top-1/3 flex justify-center'>
                <p className='text-center'>Sorry, there are no reports to see</p>
              </div>
            </div>
          )}
        </div>
      </ContentPanel>
    </>
  );
};

export default Reports;
