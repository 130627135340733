import { DayOfWeek } from '@/stores/_stores/targetConfigs';
import { startOfToday, endOfToday, startOfHour, endOfHour, eachHourOfInterval, eachMinuteOfInterval, format } from 'date-fns';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';

const getEachHourInterval = (): Date[] => {
  return eachHourOfInterval({
    start: startOfToday(),
    end: endOfToday(),
  });
};

const getEachMinuteInterval = (start: Date, end: Date, step: number = 15): Date[] => {
  return eachMinuteOfInterval(
    {
      start,
      end,
    },
    { step },
  );
};

export const getEachMinuteIntervalForEachHour = (
  start: number = 0,
  end: number = 23,
  options?: { step?: number; format?: string },
): Record<number, string[]> => {
  const timeFormat = options?.format ?? 'H:mm';
  const step = options?.step ?? 15;
  const hours = getEachHourInterval();

  return hours.reduce<Record<number, string[]>>((acc, hour) => {
    const h = hour.getHours();

    if (h < start || h > end) {
      return acc;
    }

    acc[h] = getEachMinuteInterval(startOfHour(hour), endOfHour(hour), step).map((date) => format(date, timeFormat));
    return acc;
  }, {});
};

export const getEachMinuteIntervalForEachHourAndDay = (
  start: number = 0,
  end: number = 23,
  options?: { step?: number; format?: string },
): Record<DayOfWeek, Record<number, string[]>> => {
  const days = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
  const intervals = getEachMinuteIntervalForEachHour(start, end, options);

  return days.reduce<Record<string, Record<number, string[]>>>((acc, day) => {
    acc[day] = intervals;
    return acc;
  }, {});
};

/*
Loop through each interval of the day
Sample dayIntervals:
{
  '0': ['0:00', '0:15', '0:30', '0:45'],
  '1': ['1:00', '1:15', '1:30', '1:45'],
  '2': ['2:00', '2:15', '2:30', '2:45'],
  '3': ['3:00', '3:15', '3:30', '3:45'],
  '4': ['4:00', '4:15', '4:30', '4:45'],
  '5': ['5:00', '5:15', '5:30', '5:45'],
  '6': ['6:00', '6:15', '6:30', '6:45'],
  '7': ['7:00', '7:15', '7:30', '7:45'],
  '8': ['8:00', '8:15', '8:30', '8:45'],
  '9': ['9:00', '9:15', '9:30', '9:45'],
  '10': ['10:00', '10:15', '10:30', '10:45'],
  '11': ['11:00', '11:15', '11:30', '11:45'],
  '12': ['12:00', '12:15', '12:30', '12:45'],
  '13': ['13:00', '13:15', '13:30', '13:45'],
  '14': ['14:00', '14:15', '14:30', '14:45'],
  '15': ['15:00', '15:15', '15:30', '15:45'],
  '16': ['16:00', '16:15', '16:30', '16:45'],
  '17': ['17:00', '17:15', '17:30', '17:45'],
  '18': ['18:00', '18:15', '18:30', '18:45'],
  '19': ['19:00', '19:15', '19:30', '19:45'],
  '20': ['20:00', '20:15', '20:30', '20:45'],
  '21': ['21:00', '21:15', '21:30', '21:45'],
  '22': ['22:00', '22:15', '22:30', '22:45'],
  '23': ['23:00', '23:15', '23:30', '23:45'],
}
 */
export const forEachIntervalByDay = (dayIntervals: Record<number, string[]>, callback: (interval: string) => void): void => {
  const intervals = map(dayIntervals, (intervals) => intervals);
  flattenDeep(intervals).forEach(callback);
};

// Re-calculate the adjusted value based on the new baseline value & sync options
// keepOverrides option wins over keepPercentage option
export const calculateAdjustedValue = (
  newBaseline: number | null,
  currentBaseline: number | null,
  currentAdjustedValue: number | null,
  percentage: number | null,
  keepPercentage: boolean,
  keepOverrides: boolean,
): number | null => {
  // Ensure input values are numbers, otherwise return null
  const _newBaseline = isNaN(newBaseline as number) ? null : newBaseline;
  const _currentBaseline = isNaN(currentBaseline as number) ? null : currentBaseline;
  const _currentAdjustedValue = isNaN(currentAdjustedValue as number) ? null : currentAdjustedValue;
  const _percentage = isNaN(percentage as number) ? null : percentage;

  const isCurrentAdjustedValueOverridden =
    _currentAdjustedValue !== _currentBaseline && _currentAdjustedValue !== Math.round((_currentBaseline ?? 0) * (1 + (_percentage ?? 0) / 100));

  // When keeping override value: if the current adjusted value is overridden, keep it
  if (keepOverrides && isCurrentAdjustedValueOverridden) {
    return _currentAdjustedValue;
  }

  // When keeping percentage: adjust the new baseline value
  if (keepPercentage && _newBaseline !== null) {
    return Math.round(_newBaseline * (1 + (_percentage ?? 0) / 100));
  }

  return _newBaseline;
};
