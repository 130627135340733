import classNames from 'classnames';
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const ContainedButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={classNames(
        'rounded-md min-w-fit px-3 py-2 text-emphasis-overlay font-normal bg-button-primary',
        'duration-[400ms] hover:bg-button-primary-hover hover:shadow-[0_4px_6px_1px_rgba(0,0,0,0.3)]',
        'disabled:cursor-not-allowed disabled:hover:shadow-none disabled:bg-button-primary-disabled disabled:text-emphasis-disabled disabled:ring-1 disabled:ring-button-primary-disabled-ring',
        className,
      )}
      {...rest}
    >
      <span className='flex gap-x-1 text-center justify-center'>{children}</span>
    </button>
  );
};

export const TextButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={classNames(
        'text-base font-normal text-emphasis-primary leading-6',
        'hover:underline',
        'disabled:cursor-not-allowed disabled:text-emphasis-disabled disabled:hover:no-underline',
        className,
      )}
      {...rest}
    >
      <span className='flex gap-x-1 text-center justify-center'>{children}</span>
    </button>
  );
};
