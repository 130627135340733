import { Switch as HeadlessUISwitch } from '@headlessui/react';
import classNames from 'classnames';
import React, { useState } from 'react';

interface SwitchProps {
  name: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  labelLeft?: string;
  lableRight?: string;
  disabled?: boolean;
}

const Switch = (props: SwitchProps, ref: React.Ref<HTMLButtonElement>): JSX.Element => {
  const { labelLeft, lableRight, name, onChange, value, disabled = false } = props;
  const [enabled, setEnabled] = useState(value);

  return (
    <HeadlessUISwitch.Group as='div' className='flex items-center'>
      {labelLeft !== undefined && (
        <HeadlessUISwitch.Label as='span' className='mr-3 font-normal text-emphasis-base'>
          {labelLeft}
        </HeadlessUISwitch.Label>
      )}
      <HeadlessUISwitch
        ref={ref}
        className='bg-button-primary-disabled-ring relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-button-primary focus:ring-offset-2 disabled:cursor-not-allowed'
        name={name}
        checked={enabled}
        onChange={(checked: boolean) => {
          setEnabled(checked);
          onChange?.(checked);
        }}
        disabled={disabled}
      >
        <span
          aria-hidden='true'
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-button-primary shadow ring-0 transition duration-200 ease-in-out disabled:bg-button-primary-disabled',
          )}
        />
      </HeadlessUISwitch>
      {lableRight !== undefined && (
        <HeadlessUISwitch.Label as='span' className='ml-3 font-normal text-emphasis-base'>
          {lableRight}
        </HeadlessUISwitch.Label>
      )}
    </HeadlessUISwitch.Group>
  );
};

export default React.forwardRef(Switch);
