import { createSlice, type ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { type RootState } from '..';
import { REQUEST_STATUS, type State } from '@/types';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '../utils';
import { getDevices, type Device } from './actions';

export * from './actions';

export interface DeviceState extends State<Device[]> {}

const initialState: DeviceState = {
  data: [] as Device[],
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    pushDevice: (state, { payload }) => {
      state.data.push(payload);
    },
    mergeDevice: (state, { payload }) => {
      const updatedDevice = payload as Device;
      const deviceIndex = state.data.findIndex((device) => device.serialNumber === updatedDevice.serialNumber);
      state.data[deviceIndex] = {
        ...state.data[deviceIndex],
        ...updatedDevice,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DeviceState>) => {
    builder
      .addCase(getDevices.pending, pendingReducer)
      .addCase(
        getDevices.fulfilled,
        fulfilledReducer((state, action) => (state.data = action.payload as Device[])),
      )
      .addCase(getDevices.rejected, rejectedReducer);
  },
});

export default devicesSlice.reducer;

export const { pushDevice, mergeDevice } = devicesSlice.actions;

export const selectDevices = (state: RootState): Device[] => state.devices.data;

export const selectIsDevicesLoading = (state: RootState): boolean => state.devices.requestStatus === REQUEST_STATUS.LOADING;
