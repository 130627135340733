import { REQUEST_STATUS, type State } from '@/types';
import { type ActionReducerMapBuilder, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/utils';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { isEmpty } from '@/common';
import type { RootState, Selector } from '@/stores/index';
import type { Template } from '@/stores/types';

export interface DashboardTemplate {
  id: string;
  name: string;
  deprecated: boolean;
  restrictedTo: string[];
  template: Template;
}

export interface DashboardTemplatesState extends State<DashboardTemplate[]> {}

const initialState: DashboardTemplatesState = {
  data: [] as DashboardTemplate[],
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const getDashboardTemplatesQuery = `
  query getDashboardTemplatesV2($clientId: String!) {
    getDashboardTemplatesV2(clientId: $clientId) {
      id,
      deprecated,
      name,
      restrictedTo,
      template {
        grid,
        slots
      }
    }
  }`;

export const getDashboardTemplates = createAsyncThunk<DashboardTemplate[], { clientId: string }>(
  'dashboardTemplates/getDashboardTemplates',
  async ({ clientId }): Promise<DashboardTemplate[]> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getDashboardTemplatesQuery,
      variables: {
        clientId,
      },
    });

    const dashboardTemplates = get(response, 'data.data.getDashboardTemplatesV2', null);

    if (isEmpty(dashboardTemplates) || dashboardTemplates.length === 0) {
      window.logger.warn(`No dashboard templates found for client id ${clientId}`);
      return [];
    }

    return dashboardTemplates;
  },
);

const dashboardTemplateSlice = createSlice({
  name: 'dashboardTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DashboardTemplatesState>) => {
    builder
      .addCase(getDashboardTemplates.pending, pendingReducer)
      .addCase(
        getDashboardTemplates.fulfilled,
        fulfilledReducer((state, action): void => {
          state.data = action.payload as DashboardTemplate[];
        }),
      )
      .addCase(getDashboardTemplates.rejected, rejectedReducer);
  },
});

export default dashboardTemplateSlice.reducer;

export const selectDashboardTemplates = (state: RootState): DashboardTemplate[] => state.dashboardTemplates.data;

export const selectDashboardTemplate = (templateId: string): Selector<DashboardTemplate | undefined> =>
  createSelector(selectDashboardTemplates, (templates): DashboardTemplate | undefined => templates.find((template) => template.id === templateId));

export const selectDashboardTemplatesLoading = (state: RootState): boolean => state.dashboardTemplates.requestStatus === REQUEST_STATUS.LOADING;
