import { REQUEST_STATUS, type State } from '@/types';
import { type SerializedError } from '@reduxjs/toolkit';

export const fulfilledReducer =
  <T>(callback?: (state: State<T>, action: { payload: unknown }) => void) =>
  (state: State<T>, action: { payload: unknown }): void => {
    if (callback !== undefined) {
      callback(state, action);
    } else {
      // When callback is undefined, we just update the request status
      // The result is unwrapped from where the action is dispatched
    }
    state.requestStatus = REQUEST_STATUS.SUCCEEDED;
  };

export const pendingReducer = <T>(state: State<T>): void => {
  state.requestStatus = REQUEST_STATUS.LOADING;
};

export const rejectedReducer = <T>(state: State<T>, action: { error: SerializedError }): void => {
  state.requestStatus = REQUEST_STATUS.FAILED;
  state.error = action.error;
  window.logger.error(action.error);
};

/*
Sample form error object return from the server
[
  {
    "message": "\"{\\\"errorType\\\":\\\"Error\\\",\\\"errorMessage\\\":\\\"{\\\\\\\"siteId\\\\\\\":[\\\\\\\"Site ID cannot be empty.\\\\\\\"],\\\\\\\"serialNumber\\\\\\\":[\\\\\\\"Serial Number cannot be empty.\\\\\\\"],\\\\\\\"name\\\\\\\":[\\\\\\\"Name cannot be empty.\\\\\\\"]}\\\",\\\"trace\\\":[\\\"Error: {\\\\\\\"siteId\\\\\\\":[\\\\\\\"Site ID cannot be empty.\\\\\\\"],\\\\\\\"serialNumber\\\\\\\":[\\\\\\\"Serial Number cannot be empty.\\\\\\\"],\\\\\\\"name\\\\\\\":[\\\\\\\"Name cannot be empty.\\\\\\\"]}\\\",\\\"    at /var/task/dist/devices/deviceController.js:81:27\\\",\\\"    at step (/var/task/dist/devices/deviceController.js:56:23)\\\",\\\"    at Object.next (/var/task/dist/devices/deviceController.js:37:53)\\\",\\\"    at /var/task/dist/devices/deviceController.js:31:71\\\",\\\"    at new Promise (<anonymous>)\\\",\\\"    at __awaiter (/var/task/dist/devices/deviceController.js:27:12)\\\",\\\"    at /var/task/dist/devices/deviceController.js:68:100\\\",\\\"    at /var/task/dist/utils/wrapHandler.js:46:46\\\",\\\"    at step (/var/task/dist/utils/wrapHandler.js:33:23)\\\",\\\"    at Object.next (/var/task/dist/utils/wrapHandler.js:14:53)\\\"]}\"",
    "locations": [
        {
            "line": 3,
            "column": 5
        }
    ],
    "path": [
        "createDevice"
    ],
    "extensions": {
        "code": "INTERNAL_SERVER_ERROR",
        "stacktrace": [
            "Error: \"{\\\"errorType\\\":\\\"Error\\\",\\\"errorMessage\\\":\\\"{\\\\\\\"siteId\\\\\\\":[\\\\\\\"Site ID cannot be empty.\\\\\\\"],\\\\\\\"serialNumber\\\\\\\":[\\\\\\\"Serial Number cannot be empty.\\\\\\\"],\\\\\\\"name\\\\\\\":[\\\\\\\"Name cannot be empty.\\\\\\\"]}\\\",\\\"trace\\\":[\\\"Error: {\\\\\\\"siteId\\\\\\\":[\\\\\\\"Site ID cannot be empty.\\\\\\\"],\\\\\\\"serialNumber\\\\\\\":[\\\\\\\"Serial Number cannot be empty.\\\\\\\"],\\\\\\\"name\\\\\\\":[\\\\\\\"Name cannot be empty.\\\\\\\"]}\\\",\\\"    at /var/task/dist/devices/deviceController.js:81:27\\\",\\\"    at step (/var/task/dist/devices/deviceController.js:56:23)\\\",\\\"    at Object.next (/var/task/dist/devices/deviceController.js:37:53)\\\",\\\"    at /var/task/dist/devices/deviceController.js:31:71\\\",\\\"    at new Promise (<anonymous>)\\\",\\\"    at __awaiter (/var/task/dist/devices/deviceController.js:27:12)\\\",\\\"    at /var/task/dist/devices/deviceController.js:68:100\\\",\\\"    at /var/task/dist/utils/wrapHandler.js:46:46\\\",\\\"    at step (/var/task/dist/utils/wrapHandler.js:33:23)\\\",\\\"    at Object.next (/var/task/dist/utils/wrapHandler.js:14:53)\\\"]}\"",
            "    at /Users/trungtang/Projects/eyecue-mimir/dist/utils/eyecueDashboardServiceClient.js:131:23",
            "    at step (/Users/trungtang/Projects/eyecue-mimir/dist/utils/eyecueDashboardServiceClient.js:56:23)",
            "    at Object.next (/Users/trungtang/Projects/eyecue-mimir/dist/utils/eyecueDashboardServiceClient.js:37:53)",
            "    at fulfilled (/Users/trungtang/Projects/eyecue-mimir/dist/utils/eyecueDashboardServiceClient.js:28:58)",
            "    at process.processTicksAndRejections (node:internal/process/task_queues:95:5)"
        ]
    },
    "level": "error"
  }
]
*/
export const parseError = (errors: any): string[] => errors.map((error: any) => JSON.parse(JSON.parse(error.message)).errorMessage);
