export const ScreenIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_263_26944' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='2' width='20' height='16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.49992 2.08337H17.4999C18.4166 2.08337 19.1666 2.83337 19.1666 3.75004V13.75C19.1666 14.6667 18.4166 15.4167 17.4999 15.4167H14.9999C14.5416 15.4167 14.1666 15.0417 14.1666 14.5834C14.1666 14.125 14.5416 13.75 14.9999 13.75H16.6666C17.1249 13.75 17.4999 13.375 17.4999 12.9167V4.58337C17.4999 4.12504 17.1249 3.75004 16.6666 3.75004H3.33325C2.87492 3.75004 2.49992 4.12504 2.49992 4.58337V12.9167C2.49992 13.375 2.87492 13.75 3.33325 13.75H4.99992C5.45825 13.75 5.83325 14.125 5.83325 14.5834C5.83325 15.0417 5.45825 15.4167 4.99992 15.4167H2.49992C1.58325 15.4167 0.833252 14.6667 0.833252 13.75V3.75004C0.833252 2.83337 1.58325 2.08337 2.49992 2.08337ZM13.5749 16.4917C14.0999 17.0167 13.7249 17.9167 12.9833 17.9167H7.00825C6.26659 17.9167 5.89992 17.0167 6.41659 16.4917L9.40825 13.5C9.56394 13.344 9.77532 13.2563 9.99575 13.2563C10.2162 13.2563 10.4276 13.344 10.5833 13.5L13.5749 16.4917Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_263_26944)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const DashboardIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_263_26960' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='16' height='16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2499 2.5H3.74992C2.83325 2.5 2.08325 3.25 2.08325 4.16667V6.66667H17.9166V4.16667C17.9166 3.25 17.1666 2.5 16.2499 2.5ZM16.2499 17.5H13.7499V8.33333H17.9166V15.8333C17.9166 16.75 17.1666 17.5 16.2499 17.5ZM12.0833 8.35H7.91658V17.5H12.0833V8.35ZM3.74992 17.5C2.83325 17.5 2.08325 16.75 2.08325 15.8333V8.33333H6.24992V17.5H3.74992Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_263_26960)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const ReportsIcon = (props: any): JSX.Element => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_2910_5250' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='16' height='16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16667 2.5249H15.8333C16.75 2.5249 17.5 3.2749 17.5 4.19157V15.8582C17.5 16.7749 16.75 17.5249 15.8333 17.5249H4.16667C3.25 17.5249 2.5 16.7749 2.5 15.8582V4.19157C2.5 3.2749 3.25 2.5249 4.16667 2.5249ZM5.83333 13.3582C5.83333 13.8166 6.20833 14.1916 6.66667 14.1916C7.125 14.1916 7.5 13.8166 7.5 13.3582V9.19157C7.5 8.73324 7.125 8.35824 6.66667 8.35824C6.20833 8.35824 5.83333 8.73324 5.83333 9.19157V13.3582ZM10 14.1916C9.54167 14.1916 9.16667 13.8166 9.16667 13.3582V6.69157C9.16667 6.23324 9.54167 5.85824 10 5.85824C10.4583 5.85824 10.8333 6.23324 10.8333 6.69157V13.3582C10.8333 13.8166 10.4583 14.1916 10 14.1916ZM12.5 13.3582C12.5 13.8166 12.875 14.1916 13.3333 14.1916C13.7917 14.1916 14.1667 13.8166 14.1667 13.3582V11.6916C14.1667 11.2332 13.7917 10.8582 13.3333 10.8582C12.875 10.8582 12.5 11.2332 12.5 11.6916V13.3582Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_2910_5250)'>
      <rect y='0.0249023' width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const ScheduleIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_264_33360' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99175 1.66663C5.39175 1.66663 1.66675 5.39996 1.66675 9.99996C1.66675 14.6 5.39175 18.3333 9.99175 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99996C18.3334 5.39996 14.6001 1.66663 9.99175 1.66663ZM10.0001 16.6666C6.31675 16.6666 3.33341 13.6833 3.33341 9.99996C3.33341 6.31663 6.31675 3.33329 10.0001 3.33329C13.6834 3.33329 16.6667 6.31663 16.6667 9.99996C16.6667 13.6833 13.6834 16.6666 10.0001 16.6666ZM9.76675 5.83329H9.81675C10.1501 5.83329 10.4167 6.09996 10.4167 6.43329V10.2166L13.6417 12.1333C13.9334 12.3 14.0251 12.675 13.8501 12.9583C13.6834 13.2416 13.3167 13.325 13.0334 13.1583L9.57508 11.0833C9.31675 10.9333 9.16675 10.6583 9.16675 10.3666V6.43329C9.16675 6.09996 9.43341 5.83329 9.76675 5.83329Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_264_33360)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const NotificationIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_264_33708' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33341 1.66663H16.6667C17.5834 1.66663 18.3334 2.41663 18.3334 3.33329V13.3333C18.3334 14.25 17.5834 15 16.6667 15H5.00008L1.66675 18.3333V3.33329C1.66675 2.41663 2.41675 1.66663 3.33341 1.66663ZM5.83342 9.16663H7.50008V7.49996H5.83342V9.16663ZM10.8334 9.16663H9.16675V7.49996H10.8334V9.16663ZM12.5001 9.16663H14.1667V7.49996H12.5001V9.16663Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_264_33708)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const MonitorIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_264_33745' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='3' width='20' height='14'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.75C5.83337 3.75 2.27504 6.34167 0.833374 10C2.27504 13.6583 5.83337 16.25 10 16.25C14.1667 16.25 17.725 13.6583 19.1667 10C17.725 6.34167 14.1667 3.75 10 3.75ZM10 14.1667C7.70004 14.1667 5.83337 12.3 5.83337 10C5.83337 7.7 7.70004 5.83333 10 5.83333C12.3 5.83333 14.1667 7.7 14.1667 10C14.1667 12.3 12.3 14.1667 10 14.1667ZM7.50004 10C7.50004 8.61667 8.61671 7.5 10 7.5C11.3834 7.5 12.5 8.61667 12.5 10C12.5 11.3833 11.3834 12.5 10 12.5C8.61671 12.5 7.50004 11.3833 7.50004 10Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_264_33745)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const SettingsIcon = (props: any): JSX.Element => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_264_33810' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2518 9.99996C16.2518 10.2833 16.2268 10.55 16.1935 10.8166L17.9518 12.1916C18.1102 12.3166 18.1518 12.5416 18.0518 12.725L16.3852 15.6083C16.2852 15.7916 16.0685 15.8666 15.8768 15.7916L13.8018 14.9583C13.3685 15.2833 12.9018 15.5666 12.3935 15.775L12.0768 17.9833C12.0518 18.1833 11.8768 18.3333 11.6685 18.3333H8.33515C8.12682 18.3333 7.95182 18.1833 7.92682 17.9833L7.61015 15.775C7.10182 15.5666 6.63515 15.2916 6.20182 14.9583L4.12682 15.7916C3.94349 15.8583 3.71849 15.7916 3.61849 15.6083L1.95182 12.725C1.85182 12.5416 1.89349 12.3166 2.05182 12.1916L3.81015 10.8166C3.77682 10.55 3.75182 10.275 3.75182 9.99996C3.75182 9.72496 3.77682 9.44996 3.81015 9.18329L2.05182 7.80829C1.89349 7.68329 1.84349 7.45829 1.95182 7.27496L3.61849 4.39163C3.71849 4.20829 3.93515 4.13329 4.12682 4.20829L6.20182 5.04163C6.63515 4.71663 7.10182 4.43329 7.61015 4.22496L7.92682 2.01663C7.95182 1.81663 8.12682 1.66663 8.33515 1.66663H11.6685C11.8768 1.66663 12.0518 1.81663 12.0768 2.01663L12.3935 4.22496C12.9018 4.43329 13.3685 4.70829 13.8018 5.04163L15.8768 4.20829C16.0602 4.14163 16.2852 4.20829 16.3852 4.39163L18.0518 7.27496C18.1518 7.45829 18.1102 7.68329 17.9518 7.80829L16.1935 9.18329C16.2268 9.44996 16.2518 9.71663 16.2518 9.99996ZM7.08515 9.99996C7.08515 11.6083 8.39349 12.9166 10.0018 12.9166C11.6102 12.9166 12.9185 11.6083 12.9185 9.99996C12.9185 8.39163 11.6102 7.08329 10.0018 7.08329C8.39349 7.08329 7.08515 8.39163 7.08515 9.99996Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_264_33810)'>
      <rect width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const HelpIcon = (props: any): JSX.Element => (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_264_33834' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='1' width='18' height='18'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.75 1.66663C6.14996 1.66663 2.41663 5.39996 2.41663 9.99996C2.41663 14.6 6.14996 18.3333 10.75 18.3333C15.35 18.3333 19.0833 14.6 19.0833 9.99996C19.0833 5.39996 15.35 1.66663 10.75 1.66663ZM9.91663 15.8333V14.1666H11.5833V15.8333H9.91663ZM12.5583 10.1416L13.3083 9.37496C14.1583 8.52496 14.45 7.06663 13.4666 5.70829C12.7166 4.66663 11.5083 4.00829 10.2416 4.20829C8.94996 4.40829 7.90829 5.34163 7.54996 6.56663C7.41663 7.03329 7.74996 7.49996 8.23329 7.49996H8.48329C8.80829 7.49996 9.06663 7.26663 9.16663 6.95829C9.44163 6.16663 10.3 5.64163 11.225 5.89996C11.8083 6.06663 12.275 6.57496 12.3833 7.17496C12.4916 7.75829 12.3083 8.30829 11.925 8.67496L10.8916 9.72496C10.5416 10.075 10.2666 10.5083 10.1 10.9916C9.98329 11.3333 9.91663 11.7 9.91663 12.0833V12.5H11.5833C11.5833 12.1166 11.625 11.8166 11.6916 11.55C11.8416 10.95 12.1416 10.5666 12.5583 10.1416Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_264_33834)'>
      <rect x='0.75' width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const LeftArrowIcon = (props: any): JSX.Element => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_266_37923' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='3' y='4' width='14' height='14'>
      <path
        d='M15.6594 10.1391H6.35107L10.4177 6.07246C10.7427 5.74746 10.7427 5.21412 10.4177 4.88912C10.262 4.73308 10.0507 4.64539 9.83024 4.64539C9.60981 4.64539 9.39843 4.73308 9.24274 4.88912L3.75107 10.3808C3.42607 10.7058 3.42607 11.2308 3.75107 11.5558L9.24274 17.0475C9.56774 17.3725 10.0927 17.3725 10.4177 17.0475C10.7427 16.7225 10.7427 16.1975 10.4177 15.8725L6.35107 11.8058H15.6594C16.1177 11.8058 16.4927 11.4308 16.4927 10.9725C16.4927 10.5141 16.1177 10.1391 15.6594 10.1391Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_266_37923)'>
      <rect y='0.968262' width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const HomeIcon = (props: any): JSX.Element => (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_395_77463' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='4' width='17' height='14'>
      <path
        d='M8.83146 17.0745V12.9079H12.1648V17.0745C12.1648 17.5329 12.5398 17.9079 12.9981 17.9079H15.4981C15.9565 17.9079 16.3315 17.5329 16.3315 17.0745V11.2412H17.7481C18.1315 11.2412 18.3148 10.7662 18.0231 10.5162L11.0565 4.24119C10.7398 3.95785 10.2565 3.95785 9.9398 4.24119L2.97313 10.5162C2.6898 10.7662 2.8648 11.2412 3.24813 11.2412H4.6648V17.0745C4.6648 17.5329 5.0398 17.9079 5.49813 17.9079H7.99813C8.45646 17.9079 8.83146 17.5329 8.83146 17.0745Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_395_77463)'>
      <rect x='0.5' y='0.968262' width='20' height='20' fill='#FFFFFF' {...props} />
    </g>
  </svg>
);

export const PlusIcon = (props: any): JSX.Element => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='#025DE4' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12.8627C2 7.34267 6.48 2.86267 12 2.86267C17.52 2.86267 22 7.34267 22 12.8627C22 18.3827 17.52 22.8627 12 22.8627C6.48 22.8627 2 18.3827 2 12.8627ZM13 13.8627H16C16.55 13.8627 17 13.4127 17 12.8627C17 12.3127 16.55 11.8627 16 11.8627H13V8.86267C13 8.31267 12.55 7.86267 12 7.86267C11.45 7.86267 11 8.31267 11 8.86267V11.8627H8C7.45 11.8627 7 12.3127 7 12.8627C7 13.4127 7.45 13.8627 8 13.8627H11V16.8627C11 17.4127 11.45 17.8627 12 17.8627C12.55 17.8627 13 17.4127 13 16.8627V13.8627Z'
    />
  </svg>
);

export const LogoutIcon = (props: any): JSX.Element => (
  <svg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_2625_15722' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='4' width='20' height='14'>
      <path
        d='M1.66667 14.0629C1.66667 14.9795 2.41667 15.7295 3.33333 15.7295H0.833334C0.375 15.7295 0 16.1045 0 16.5629C0 17.0212 0.375 17.3962 0.833334 17.3962H19.1667C19.625 17.3962 20 17.0212 20 16.5629C20 16.1045 19.625 15.7295 19.1667 15.7295H16.6667C17.5917 15.7295 18.3333 14.9795 18.3333 14.0629V5.72953C18.3333 4.81287 17.5833 4.06287 16.6667 4.06287H3.33333C2.41667 4.06287 1.66667 4.81287 1.66667 5.72953V14.0629ZM9.16667 11.5159V13.3409L6.15833 10.5243C5.98333 10.3576 5.98333 10.0826 6.15833 9.91595L9.16667 7.11595V8.89095C12.4083 9.34095 13.7 11.5576 14.1667 13.7826C13.0083 12.2243 11.4833 11.5159 9.16667 11.5159Z'
        fill='black'
      />
      <path
        d='M1.66667 14.0629C1.66667 14.9795 2.41667 15.7295 3.33333 15.7295H0.833334C0.375 15.7295 0 16.1045 0 16.5629C0 17.0212 0.375 17.3962 0.833334 17.3962H19.1667C19.625 17.3962 20 17.0212 20 16.5629C20 16.1045 19.625 15.7295 19.1667 15.7295H16.6667C17.5917 15.7295 18.3333 14.9795 18.3333 14.0629V5.72953C18.3333 4.81287 17.5833 4.06287 16.6667 4.06287H3.33333C2.41667 4.06287 1.66667 4.81287 1.66667 5.72953V14.0629ZM9.16667 11.5159V13.3409L6.15833 10.5243C5.98333 10.3576 5.98333 10.0826 6.15833 9.91595L9.16667 7.11595V8.89095C12.4083 9.34095 13.7 11.5576 14.1667 13.7826C13.0083 12.2243 11.4833 11.5159 9.16667 11.5159Z'
        stroke='black'
      />
    </mask>
    <g mask='url(#mask0_2625_15722)'>
      <path d='M0 0.729553H20V20.7296H0V0.729553Z' fill='#00BD90' {...props} />
    </g>
  </svg>
);

export const ChevronLeftIcon = (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-6 h-6 stroke-[#00B780]'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' />
  </svg>
);

export const ChevronRightIcon = (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-6 h-6 stroke-[#00B780]'>
    <path strokeLinecap='round' strokeLinejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' />
  </svg>
);

export const ChevronDownIcon = (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' className='w-6 h-6 stroke-[#00B780]'>
    <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
  </svg>
);

export const DotIcon = (props: any): JSX.Element => (
  <svg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.0189209' width='19' height='19' rx='9.5' fill='#A7ACAF' {...props} />
  </svg>
);

export const RevertIcon = (prop: any) => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='#858C94' xmlns='http://www.w3.org/2000/svg' {...prop}>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9.96861 4.81967V2.49467C9.96861 2.11967 9.51861 1.93633 9.26028 2.203L6.09361 5.36133C5.92694 5.528 5.92694 5.78633 6.09361 5.953L9.25194 9.11133C9.51861 9.36967 9.96861 9.18633 9.96861 8.81133V6.48633C13.0769 6.48633 15.5353 9.33633 14.8519 12.5613C14.4603 14.453 12.9269 15.978 11.0436 16.3697C8.06861 16.9947 5.41861 14.953 5.01861 12.1947C4.96028 11.7947 4.61028 11.4863 4.20194 11.4863C3.70194 11.4863 3.30194 11.928 3.36861 12.428C3.88528 16.0863 7.36861 18.7947 11.3103 18.028C13.9103 17.5197 16.0019 15.428 16.5103 12.828C17.3353 8.553 14.0853 4.81967 9.96861 4.81967Z'
    />
  </svg>
);

export const SyncIcon = (prop: any) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='black' xmlns='http://www.w3.org/2000/svg' {...prop}>
    <path
      id='Round'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2.32758V4.11758C16.42 4.11758 20 7.69758 20 12.1176C20 13.1576 19.8 14.1576 19.43 15.0676C19.16 15.7376 18.3 15.9176 17.79 15.4076C17.52 15.1376 17.41 14.7276 17.56 14.3676C17.85 13.6776 18 12.9076 18 12.1176C18 8.80758 15.31 6.11758 12 6.11758V7.90758C12 8.35758 11.46 8.57758 11.14 8.26758L8.35 5.47758C8.15 5.27758 8.15 4.96758 8.35 4.76758L11.15 1.97758C11.46 1.65758 12 1.87758 12 2.32758ZM6 12.1176C6 15.4276 8.69 18.1176 12 18.1176V16.3276C12 15.8776 12.54 15.6576 12.85 15.9676L15.64 18.7576C15.84 18.9576 15.84 19.2676 15.64 19.4676L12.85 22.2576C12.54 22.5776 12 22.3576 12 21.9076V20.1176C7.58 20.1176 4 16.5376 4 12.1176C4 11.0776 4.2 10.0776 4.57 9.16758C4.84 8.49758 5.7 8.31758 6.21 8.82758C6.48 9.09758 6.59 9.50758 6.44 9.86758C6.15 10.5576 6 11.3276 6 12.1176Z'
    />
  </svg>
);
