import { createSlice, type ActionReducerMapBuilder, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { type Selector, type RootState } from './index';
import get from 'lodash/get';
import createHttp from '@/services/http';
import { REQUEST_STATUS, type State } from '@/types';
import { fulfilledReducer, pendingReducer, rejectedReducer } from './utils';
import { isEmpty } from '@/common';

export interface ClientConfigs {
  id: string;
  name: string;
}

export interface Store {
  id: string;
  name: string;
  storeId: string;
}

export interface StoreState
  extends State<{
    stores: Store[];
    clientConfig?: ClientConfigs;
  }> {}

const initialState: StoreState = {
  data: {
    stores: [],
    clientConfig: undefined,
  },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const getStoreQuery = `
  query getStore($site_id_list :[String!], $organization:String!){
    getStores(site_id_list: $site_id_list, organization: $organization ){
      name,
      id,
      storeId: fingermark_id
    }
    getClientConfig(clientId: $organization){
      clientId,
      clientName,
    }
  }`;

export const getUserStores = createAsyncThunk<{ stores: Store[]; clientConfig: ClientConfigs }, { organization: string; allowedStoreIds: string[] }>(
  'stores/getUserStores',
  async ({ organization, allowedStoreIds }): Promise<{ stores: Store[]; clientConfig: ClientConfigs }> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getStoreQuery,
      variables: {
        site_id_list: allowedStoreIds,
        organization,
      },
    });

    const stores = get(response, 'data.data.getStores', undefined);
    const clientData = get(response, 'data.data.getClientConfig', undefined);

    if (isEmpty(clientData)) {
      window.logger.error(`No client config found for organization ${organization}`);
    }

    if (isEmpty(stores) || stores.length === 0) {
      window.logger.error(`No matching stores found for organization ${organization}`);
    }

    return {
      stores: (stores ?? ([] as Store[])).map((store: Store) => ({
        ...store,
        storeId: store.storeId.trim(),
        name: store.name.trim(),
      })),
      clientConfig: {
        id: clientData.clientId,
        name: clientData.clientName,
      },
    };
  },
);

export const clientConfigSlice = createSlice({
  name: 'clientConfig',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<StoreState>): void => {
    builder
      .addCase(getUserStores.pending, pendingReducer)
      .addCase(
        getUserStores.fulfilled,
        fulfilledReducer((state, action): void => {
          const { clientConfig, stores } = action.payload as { clientConfig: ClientConfigs; stores: Store[] };
          state.data.stores = stores;
          state.data.clientConfig = clientConfig;
        }),
      )
      .addCase(getUserStores.rejected, rejectedReducer);
  },
});

export default clientConfigSlice.reducer;

export const selectStores = (state: RootState): Store[] => state.clientConfigs.data.stores;

export const selectStore = (storeId: string): Selector<Store | undefined> =>
  createSelector([selectStores], (stores) => stores.find((store) => store.storeId === storeId));

export const selectClientConfig = (state: RootState): ClientConfigs | undefined => state.clientConfigs.data.clientConfig;

export const selectFilteredStores = (filterText: string): Selector<Store[]> =>
  createSelector([selectStores], (stores) => {
    if (filterText === '' || filterText === undefined) {
      return stores;
    }

    return stores.filter((store) => store.name.toLowerCase().includes(filterText.toLowerCase()));
  });

export const selectIsStoreLoading = (state: RootState): boolean => state.clientConfigs.requestStatus === REQUEST_STATUS.LOADING;
