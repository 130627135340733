import { REQUEST_STATUS, type State } from '@/types';
import { createSlice } from '@reduxjs/toolkit';
import { getStoreData } from '@/stores/_stores/actions';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/utils';
import { type RootState } from '@/stores';

export interface StoreDetails {
  storeId: string;
  details: {
    name: string;
    timezone: string;
  };
}

export interface GeneralStoreConfigState extends State<StoreDetails | undefined> {}

const initialState: GeneralStoreConfigState = {
  data: undefined,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const generalStoreConfigSlice = createSlice({
  name: 'generalStoreConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoreData.pending, pendingReducer)
      .addCase(
        getStoreData.fulfilled,
        fulfilledReducer((state, action) => {
          state.data = action.payload as StoreDetails;
        }),
      )
      .addCase(getStoreData.rejected, rejectedReducer);
  },
});

export default generalStoreConfigSlice.reducer;

export const selectStoreTimeZone = (state: RootState): string => state.generalStoreConfigs.data?.details.timezone ?? 'UTC';
