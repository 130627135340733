import { Outlet, useParams } from 'react-router-dom';
import { Sidebar, SidebarMenuItem } from './components/sidebar';
import {
  compileNavigation,
  dashboardNavigation,
  logoutNavigation,
  monitorNavigation,
  reportsNavigation,
  screenNavigation,
  storesNavigation,
  settingsNavigation,
} from './model';
import { useMemo } from 'react';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DesktopMasterLayout = (): JSX.Element => {
  const { storeId } = useParams();

  const {
    [FeatureFlags.eyecueAdminUseMonitor]: eyecueAdminUseMonitor = false,
    [FeatureFlags.eyecueAdminUseReports]: eyecueAdminUseReports = false,
    [FeatureFlags.eyecueAdminUseSettingsTarget]: eyecueAdminUseSettingsTarget = false,
  } = useFlags();

  const navigations = useMemo(() => {
    const logout = [logoutNavigation];
    const stores = [storesNavigation];
    const reports = (eyecueAdminUseReports as boolean) ? [reportsNavigation] : [];
    const screen = [screenNavigation];
    const dashboard = [dashboardNavigation];
    const monitor = (eyecueAdminUseMonitor as boolean) ? [monitorNavigation] : [];
    const settings = (eyecueAdminUseSettingsTarget as boolean) ? [settingsNavigation] : [];

    return [...logout, ...stores, ...reports, ...screen, ...dashboard, ...monitor, ...settings];
  }, [eyecueAdminUseMonitor, eyecueAdminUseReports]);

  const navigationModel = useMemo(() => {
    return compileNavigation(navigations, { storeId: storeId ?? '' });
  }, [storeId, navigations]);

  return (
    <>
      <Sidebar>
        {navigationModel.map((item) => (
          <SidebarMenuItem key={`${item.name}`} {...item} />
        ))}
      </Sidebar>
      <main className='pl-20 4xl:pl-72'>
        <Outlet />
      </main>
    </>
  );
};

export default DesktopMasterLayout;
