import { createSlice, createAsyncThunk, type ActionReducerMapBuilder, createSelector } from '@reduxjs/toolkit';
import { type Selector, type RootState } from '.';
import get from 'lodash/get';
import createHttp from '@/services/http';
import { REQUEST_STATUS, type State } from '@/types';
import { fulfilledReducer, parseError, pendingReducer, rejectedReducer } from './utils';
import { isEmpty } from '@/common';
import type { Template } from '@/stores/types';

export interface NewDashboard {
  storeId: string;
  name: string;
  template: Template;
}

export interface Dashboard {
  id: string;
  name: string;
  storeId: string;
}

export interface DashboardState extends State<Dashboard[]> {}

const initialState: DashboardState = {
  data: [] as Dashboard[],
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const getDashboardsQuery = `
  query getDashboards($site_id: String){
    getDashboards(site_id: $site_id){
      id,
      storeId: siteId,
      name
    }
  }`;

export const getDashboards = createAsyncThunk<Dashboard[], { storeId: string }>('dashboards/getDashboards', async ({ storeId }): Promise<Dashboard[]> => {
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: getDashboardsQuery,
    variables: {
      site_id: storeId,
    },
  });

  const dashboards = get(response, 'data.data.getDashboards', null);

  if (isEmpty(dashboards) || dashboards.length === 0) {
    window.logger.warn(`No dashboards found for store ${storeId}`);
    return [];
  }

  return dashboards;
});

const createDashboardQuery = `
  mutation createDashboard($siteId: String, $name: String, $template: JSON) {
    createDashboard(siteId: $siteId, name: $name, template: $template) {
      id,
      siteId,
      name,
    }
  }`;

export const createDashboard = createAsyncThunk<Dashboard, NewDashboard>(
  'dashboards/createDashboard',
  async ({ storeId, name, template }, { rejectWithValue }): Promise<any> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: createDashboardQuery,
      variables: {
        siteId: storeId,
        name,
        template,
      },
    });

    const dashboard = get(response, 'data.data.createDashboard', null);

    if (dashboard === null) {
      // Parse error messesage and reject with value
      window.logger.error(`Failed to create dashboard for site ${storeId}`);
      const parsedError = parseError(response.data.errors);
      return rejectWithValue(parsedError);
    }

    return dashboard;
  },
);

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      .addCase(getDashboards.pending, pendingReducer)
      .addCase(
        getDashboards.fulfilled,
        fulfilledReducer((state, action): void => {
          state.data = action.payload as Dashboard[];
        }),
      )
      .addCase(getDashboards.rejected, rejectedReducer);
  },
});

export default dashboardsSlice.reducer;

export const selectDashboards = (state: RootState): Dashboard[] => state.dashboards.data;

export const selectDashboard = (dashboardId?: string): Selector<Dashboard | undefined> =>
  createSelector(selectDashboards, (dashboards): Dashboard | undefined => dashboards.find((dashboard) => dashboard.id === dashboardId));

export const selectIsDashboardsLoading = (state: RootState): boolean => state.dashboards.requestStatus === REQUEST_STATUS.LOADING;
