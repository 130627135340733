import { REQUEST_STATUS, type State } from '@/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { type RootState } from '../index';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/utils';
import { getStoreData, updateTarget, revertToBaseline } from '@/stores/_stores/actions';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { subWeeks } from 'date-fns';

export enum TargetType {
  DepartureCount = 'DEPARTURE_COUNT',
  BoomGateTime = 'BOOM_GATE_TIME',
  OrderTime = 'ORDER_TIME',
  PayTime = 'PAY_TIME',
  PickupTime = 'PICKUP_TIME',
  ParkedBayTime = 'PARKED_BAY_TIME',
  PullForwardTime = 'PULL_FORWARD_TIME',
  TotalExperienceTime = 'TOTAL_EXPERIENCE_TIME',
  OefdTime = 'OEFD_TIME',
}

export enum TargetGranularity {
  Fixed = 'FIXED',
  Five = '5',
  Ten = '10',
  Fifteen = '15',
  Twenty = '20',
  Thirty = '30',
  Sixty = '60',
}

export enum DayOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export type TargetInterval = Record<DayOfWeek, Record<string, number | null>>;

export interface Target {
  type: TargetType;
  granularity: TargetGranularity;
  interval?: TargetInterval; // Based value
  adjustedInterval?: TargetInterval; // Adjusted value
  fixed?: number;
  lastUpdated?: string;
}

export interface StoreTarget {
  storeId: string;
  targets?: Target[];
}

// Keep track the last expanded day to improve user experience
export interface LastExpandedDay {
  targetIndex: number;
  day: string;
}

export interface TargetState extends State<{ storeTarget?: StoreTarget; lastExpandedDay?: LastExpandedDay }> {}

const initialState: TargetState = {
  data: { storeTarget: undefined, lastExpandedDay: undefined },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const targetConfigsSlice = createSlice({
  name: 'targetConfigs',
  initialState,
  reducers: {
    setLastExpandedDay: (state, { payload }: { payload: LastExpandedDay }) => {
      state.data.lastExpandedDay = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTarget.pending, pendingReducer)
      .addCase(
        updateTarget.fulfilled,
        fulfilledReducer((state, action) => {
          state.data.storeTarget = action.payload as StoreTarget;
        }),
      )
      .addCase(updateTarget.rejected, rejectedReducer);

    builder
      .addCase(revertToBaseline.pending, pendingReducer)
      .addCase(
        revertToBaseline.fulfilled,
        fulfilledReducer(), // Use empty fulfilledReducer to update request status only
      )
      .addCase(revertToBaseline.rejected, rejectedReducer);

    builder
      .addCase(getStoreData.pending, pendingReducer)
      .addCase(
        getStoreData.fulfilled,
        fulfilledReducer((state, action) => {
          state.data.storeTarget = action.payload as StoreTarget;
        }),
      )
      .addCase(getStoreData.rejected, rejectedReducer);

    builder
      .addCase(getHistoricalData.pending, pendingReducer)
      .addCase(
        getHistoricalData.fulfilled,
        fulfilledReducer(), // Use empty fulfilledReducer to update request status only
      )
      .addCase(getHistoricalData.rejected, rejectedReducer);
  },
});

export default targetConfigsSlice.reducer;

export const { setLastExpandedDay } = targetConfigsSlice.actions;

export const selectIsTargetsLoading = (state: RootState): boolean => state.targetConfigs.requestStatus === REQUEST_STATUS.LOADING;

export const selectStoreTarget = (state: RootState): StoreTarget | undefined => state.targetConfigs.data.storeTarget;

export const selectLastExpandedDay = (state: RootState): LastExpandedDay | undefined => state.targetConfigs.data.lastExpandedDay;

const dateToIsoWithoutMilliseconds = (date: Date): string => date.toISOString().split('.')[0] + 'Z';

export const getHistoricalData = createAsyncThunk<TargetInterval | undefined, { clientId: string; storeId: string }>(
  '_stores/getHistoricalData',
  async ({ clientId, storeId }): Promise<TargetInterval | undefined> => {
    const now = new Date();
    const dateTo = subWeeks(now, 1);
    const dateFrom = subWeeks(now, 3);

    const aucklandPacificTimezone = 'Pacific/Auckland';

    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: `query getHistoricalData($siteId: String!, $clientId: String!, $targetType: String, $timezone: String, $dateFrom: String, $dateTo: String) {
                getHistoricalData(clientId: $clientId, siteId: $siteId, targetType: $targetType, timezone: $timezone, dateFrom: $dateFrom, dateTo: $dateTo)
              }`,
      variables: {
        clientId,
        siteId: storeId,
        targetType: 'DEPARTURE_COUNT',
        timezone: aucklandPacificTimezone, // Currently getting the same response regardless of timezone (appears to be in stores local timezone)
        dateFrom: dateToIsoWithoutMilliseconds(dateFrom),
        dateTo: dateToIsoWithoutMilliseconds(dateTo),
      },
    });

    return get(response, 'data.data.getHistoricalData', null);
  },
);
