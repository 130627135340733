import MircoApp from '@/modules/MicroApp';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

const Dashboard = (): JSX.Element => {
  const { [FeatureFlags.eyecueDashboardUseVersion]: eyecueDashboardUseVersion = 'latest' } = useFlags();
  const versionPath = useMemo(() => (eyecueDashboardUseVersion === 'latest' ? '' : `/${eyecueDashboardUseVersion}`), [eyecueDashboardUseVersion]);

  return (
    <MircoApp
      index={0}
      host={process.env.REACT_APP_EYECUE_DASHBOARD !== undefined ? `${process.env.REACT_APP_EYECUE_DASHBOARD}${versionPath}` : ''}
      name='Dashboard'
    />
  );
};

export default Dashboard;
