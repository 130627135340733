import DialogWrapper, { CloseButton, ModalContent, ModalHeader, ModalTitle } from '@/modules/components/dialogWrapper';
import { ContainedButton, TextButton } from '@/modules/components/form/Buttons';
import createConfirmDialog, { type ConfirmDialogProps } from '@/modules/components/confirmDialog';
import React from 'react';

interface BasicConfirmDialogProps extends ConfirmDialogProps {
  title: string;
  message: string | JSX.Element | JSX.Element[];
}

const ConfirmDialog = (props: BasicConfirmDialogProps): JSX.Element => {
  const { reject, confirm, close, title, message, ...rest } = props;

  return (
    <DialogWrapper {...rest}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <div className='flex flex-1 justify-end'>
          <CloseButton onClick={close} />
        </div>
      </ModalHeader>
      <ModalContent>
        <div className='grid grid-cols-1 gap-y-8 content-evenly text-emphasis-secondary text-base font-normal'>
          {message}
          <div className='flex items-center justify-center gap-x-16'>
            <TextButton
              onClick={() => {
                reject(false);
              }}
            >
              No
            </TextButton>
            <ContainedButton
              onClick={() => {
                confirm(true);
              }}
            >
              Yes
            </ContainedButton>
          </div>
        </div>
      </ModalContent>
    </DialogWrapper>
  );
};

export default createConfirmDialog(ConfirmDialog);
