// This file contains the logic for the synchronous confirmation dialogWrapper. The dialogWrapper component itself should be excluded from this file.

import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

export interface ConfirmDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  reject: (reason?: any) => void;
  confirm: (value?: any) => void;
  close: () => void;
}

const createDomTreeMounter = () => {
  const confirms: Record<string, any> = {};

  const mount = (Component: React.ComponentType<any>, props: any, mountNode: any): string => {
    const key = Math.floor(Math.random() * (1 << 30)).toString(16);
    const wrapper = (mountNode ?? document.getElementById('modal-root') ?? document.body).appendChild(document.createElement('div'));
    confirms[key] = wrapper;
    const root = createRoot(wrapper);
    root.render(<Component {...props} />);

    return key;
  };

  const unmount = (key: string): void => {
    const wrapper = confirms[key];
    delete confirms[key];

    if (wrapper && wrapper.parentNode) {
      wrapper.parentNode.removeChild(wrapper);
    }
  };

  return {
    mount,
    unmount,
  };
};

const confirmable =
  (Component: React.ComponentType<any>) =>
  (props: any): JSX.Element => {
    const { resolve, reject, dispose, ...rest } = props;
    const [open, setOpen] = useState(true);

    const dismiss = () => {
      setOpen(false);
      dispose();
    };

    const cancel = (value: any) => {
      setOpen(false);
      reject(value);
    };

    const proceed = (value: any) => {
      setOpen(false);
      resolve(value);
    };

    return <Component open={open} setOpen={dismiss} reject={cancel} confirm={proceed} close={dismiss} {...rest} />;
  };

const createConfirmationCreater = (mounter: any) => (Component: React.ComponentType<any>, mountingNode?: any) => {
  const Dialog = confirmable(Component);

  return (props?: any) => {
    let mountId: string;

    const dispose = (): void => {
      setTimeout(() => {
        mounter.unmount(mountId);
      }, 1000);
    };

    const promise = new Promise((resolve, reject) => {
      try {
        mountId = mounter.mount(Dialog, { resolve, reject, dispose, ...props }, mountingNode);
      } catch (e) {
        window.logger.error(e);
        throw e;
      }
    });

    return promise.then(
      (result) => {
        dispose();
        return result;
      },
      (reason) => {
        dispose();
        return reason;
      },
    );
  };
};

export default createConfirmationCreater(createDomTreeMounter());
