import { useAppSelector } from '@/common';
import Header, { Title } from '@/modules/components/header';
import { selectFilteredStores, selectIsStoreLoading } from '@/stores/clientConfigs';
import { NavLink } from 'react-router-dom';
import { compileNavigation, dashboardNavigation, monitorNavigation, reportsNavigation, screenNavigation, settingsNavigation } from '@/layouts/model';
import { useMemo, useState } from 'react';
import { NavigationModel } from '@/layouts/components/sidebar';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import ContentPanel from '../components/contentPanel';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Store = (): JSX.Element => {
  const [filterText, setFilterText] = useState('');
  const stores = useAppSelector(selectFilteredStores(filterText));
  const isLoading = useAppSelector(selectIsStoreLoading);

  const {
    [FeatureFlags.eyecueAdminUseMonitor]: eyecueAdminUseMonitor = false,
    [FeatureFlags.eyecueAdminUseReports]: eyecueAdminUseReports = false,
    [FeatureFlags.eyecueAdminUseSettingsTarget]: eyecueAdminUseSettingsTarget = false,
  } = useFlags();

  const navigations = useMemo(() => {
    const reports = (eyecueAdminUseReports as boolean) ? [reportsNavigation] : [];
    const screen = [screenNavigation];
    const dashboard = [dashboardNavigation];
    const monitor = (eyecueAdminUseMonitor as boolean) ? [monitorNavigation] : [];
    const settings = (eyecueAdminUseSettingsTarget as boolean) ? [settingsNavigation] : [];

    return [...reports, ...screen, ...dashboard, ...monitor, ...settings];
  }, [eyecueAdminUseMonitor, eyecueAdminUseSettingsTarget]);

  const storeCompiledNavigationModel = useMemo(
    () =>
      stores.reduce(
        (result, item) => {
          result[item.storeId] = compileNavigation(navigations, { storeId: item.storeId });
          return result;
        },
        {} as Record<string, NavigationModel[]>,
      ),
    [stores, navigations],
  );

  return (
    <>
      <Header>
        <Title>Stores</Title>
        <div className='flex flex-1 justify-end'>
          <div className='relative mt-2 flex items-center'>
            <input
              type='text'
              name='search'
              className='text-emphasis-solid ring-gray-300 placeholder:text-emphasis-base focus:ring-indigo-600 block w-full rounded-full border-0 py-1.5 pr-10 pl-4 ring-1 ring-inset focus:outline-none focus:ring-2 focus:border-transparent'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </div>
          </div>
        </div>
      </Header>
      <ContentPanel isLoading={isLoading} className='pl-12 pr-12 pt-6'>
        <div className='flex flex-wrap'>
          {stores.map((store) => {
            return (
              <div key={store.id} className='group relative flex flex-col overflow-hidden rounded-md border border-outline-primary w-[250px] m-5'>
                <ul role='list' className='divide-y divide-outline-primary'>
                  <li className='px-6 py-4 text-base font-semibold text-emphasis-overlay bg-menu-background'>{store.name}</li>
                  {storeCompiledNavigationModel[store.storeId].map((item) => (
                    <li key={item.name} className='px-6 py-4 text-base font-normal text-emphasis-primary'>
                      <NavLink className='flex items-center gap-x-3' to={item.href}>
                        <item.icon className='fill-emphasis-primary' />
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </ContentPanel>
    </>
  );
};

export default Store;
