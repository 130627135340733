import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/common/assets/style/index.css';
import reportWebVitals from './reportWebVitals';
import { ApplicationContext, type AppMetadata } from '@/types';
import { createCustomLogger } from '@fingermarkglobal/eyecue-app-common/logging';
import kebabCase from 'lodash/kebabCase';
import { store } from './stores';
import { Provider as DataProvider } from 'react-redux';
import { AuthProvider } from './common';
import AppRouter from './routing';
import * as Sentry from '@sentry/react';

const env = process.env.SENTRY_ENV || 'local';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions,
  environment: env,
});

declare global {
  interface Window {
    logger: any;
    renderAdmin: (containerId: string) => void;
    unmountAdmin: (containerId: string) => void;
  }
}

// Global logger
window.logger = createCustomLogger({ label: 'eyecue-admin' });

const getAppMetadata = (el: HTMLElement): AppMetadata => {
  const appMetadata: AppMetadata = {
    username: undefined,
    organization: undefined,
    allowedStoreIds: undefined,
  };

  Object.keys(appMetadata).map((key): void => {
    appMetadata[key as keyof AppMetadata] = el.getAttribute(`data-${kebabCase(key)}`) ?? undefined;
  });

  return appMetadata;
};

// Running eyecue admin as standalone app
const dashboardRootEl = document.getElementById('admin-root');
if (dashboardRootEl !== null) {
  const appMetadata = getAppMetadata(dashboardRootEl);
  const root = ReactDOM.createRoot(dashboardRootEl);

  // Use preloaded state when run as standalone app
  // This mode will use data from './src/stores/preloadedState.ts'
  root.render(
    <ApplicationContext.Provider value={appMetadata}>
      <DataProvider store={store}>
        <AppRouter />
      </DataProvider>
    </ApplicationContext.Provider>,
  );
}

let reactDOMRoot: ReactDOM.Root | undefined;

// Global method to mount admin as micro front-end into shawdow DOM
window.renderAdmin = (containerId: string): void => {
  window.logger.debug(`renderAdmin ${containerId}`);

  const el = document.getElementById(containerId) as HTMLElement;
  const appMetadata = getAppMetadata(el);
  reactDOMRoot = ReactDOM.createRoot(el);

  const authContext = localStorage.getItem('auth.context');
  const providerConfig = JSON.parse(authContext ?? '{}');

  reactDOMRoot.render(
    <ApplicationContext.Provider value={appMetadata}>
      <AuthProvider {...providerConfig}>
        <DataProvider store={store}>
          <AppRouter />
        </DataProvider>
      </AuthProvider>
    </ApplicationContext.Provider>,
  );
};

window.unmountAdmin = (containerId: string): void => {
  window.logger.debug(`unmountAdmin ${containerId}`);

  reactDOMRoot?.unmount();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
