import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import * as PopperJS from '@popperjs/core';
import styled from 'styled-components';
import classNames from 'classnames';

const PopperWrapper = styled.div`
  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -2px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -2px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -2px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -2px;
  }
`;

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
  anchorElement: HTMLElement | null;
  children: string | JSX.Element | JSX.Element[];
  visible?: boolean;
  options?: PopperJS.Options;
}

// Note: the usePopper hook intentionally takes the DOM node, not refs, in order to be able to update when the nodes change.
// A callback ref is used here to permit this behaviour, and useState is an appropriate way to implement this.
// https://popper.js.org/react-popper/v2/
const Popper = (props: PopperProps): JSX.Element => {
  const { anchorElement, children, options, className, visible = false, ...rest } = props;
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLSpanElement | null>(null);
  const [visibleState, setVisibleState] = useState<boolean>(false);

  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    ...options,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 5] } },
    ],
  });

  useEffect(() => {
    setVisibleState(visible);
  }, [visible]);

  return anchorElement !== null && visibleState ? (
    <div
      className='fixed top-0 left-0 w-full h-full p-8 text-base bg-black bg-opacity-0 text-left z-50 overflow-hidden'
      onClick={() => {
        setVisibleState(false);
      }}
    >
      <PopperWrapper
        ref={setPopperElement}
        style={styles.popper}
        className={classNames('relative rounded-md z-50 shadow-[0_4px_6px_1px_rgba(0,0,0,0.3)]', className)}
        {...rest}
        {...attributes.popper}
      >
        {children}
        <span ref={setArrowElement} style={styles.arrow} {...attributes.arrow} className='arrow' />
      </PopperWrapper>
    </div>
  ) : (
    <></>
  );
};

export default Popper;
