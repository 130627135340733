import { useAppSelector, useCurrentStore } from '@/common';
import Header, { Title } from '@/modules/components/header';
import { PlusIcon } from '@/common/assets/icons';
import NewDeviceForm from './newDeviceForm';
import { useState } from 'react';
import ContentPanel from '../components/contentPanel';
import { selectDevices, selectIsDevicesLoading } from '@/stores/devices';
import DeviceItem from './deviceItem';
import Feature from '../components/feature';
import { TextButton } from '@/modules/components/form/Buttons';
import { FeatureFlags } from '@/types';

const Device = (): JSX.Element => {
  const currentStore = useCurrentStore();
  const [open, setOpen] = useState(false);
  const devices = useAppSelector(selectDevices);
  const isLoading = useAppSelector(selectIsDevicesLoading);

  return (
    <>
      <Header>
        <Title>{currentStore?.name ?? ''}</Title>
        <Feature flag={FeatureFlags.eyecueAdminUseAddNewDevice}>
          <div className='flex flex-1 justify-end'>
            <TextButton onClick={() => setOpen(true)} disabled={currentStore === undefined}>
              <PlusIcon className='fill-emphasis-primary' />
              Add new screen
            </TextButton>
          </div>
        </Feature>
      </Header>
      <ContentPanel isLoading={isLoading}>
        <ul role='list' className='divide-y divide-divider-light'>
          {devices.map((device) => (
            <li key={device.serialNumber} className='flex justify-between gap-x-6 py-6'>
              <DeviceItem device={device} />
            </li>
          ))}
        </ul>
      </ContentPanel>

      <NewDeviceForm storeId={currentStore?.storeId ?? ''} open={open} setOpen={setOpen} />
    </>
  );
};

export default Device;
