import Spinner from '../spinner';
import classNames from 'classnames';

export interface ContentPanelProps {
  children: JSX.Element | JSX.Element[];
  isLoading?: boolean;
  className?: string;
}

const ContentPanel = (props: ContentPanelProps): JSX.Element => {
  const { children, isLoading = false, className } = props;

  return (
    <div className={classNames('py-5 px-8', className)}>
      <Spinner hide={!isLoading} />
      {children}
    </div>
  );
};

export default ContentPanel;
