import { useEffect } from 'react';

const MircoApp = (props: { index: number; host: string; name: string; metadata?: Record<string, string> }): JSX.Element => {
  const { index, host, name, metadata = {} } = props;
  const containerId = `${name}-container-${index}`;

  useEffect(() => {
    const scriptId = `micro-component-script-${name}`;

    if (document.getElementById(scriptId) !== null) {
      renderComponent();
    } else {
      const script = document.createElement('script');
      script.id = scriptId;
      script.crossOrigin = '';
      script.src = `${host}/bundle.js`;
      script.onload = renderComponent;
      document.head.appendChild(script);
    }

    return (): void => {
      (window as any)[`unmount${name}`](containerId);
    };
  }, []);

  const renderComponent = (): void => {
    (window as any)[`render${name}`](containerId);
  };

  return <div id={containerId} {...metadata} />;
};

export default MircoApp;
