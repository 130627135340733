import Header, { Title } from '../components/header';
import { useAppSelector, useCurrentStore } from '@/common';
import { Outlet } from 'react-router-dom';
import { selectIsDashboardsLoading } from '@/stores/dashboards';
import ContentPanel from '../components/contentPanel';
import { selectDashboardTemplatesLoading } from '@/stores/dashboardTemplates';
import Feature from '@/modules/components/feature';
import { FeatureFlags } from '@/types';
import { Navigation, NavigationItem } from '@/modules/components/navigation';

const DashboardAdmin = (): JSX.Element => {
  const currentStore = useCurrentStore();
  const isDashboardsLoading = useAppSelector(selectIsDashboardsLoading);
  const isTemplatesLoading = useAppSelector(selectDashboardTemplatesLoading);

  return (
    <>
      <Header>
        <Title>{currentStore?.name ?? ''}</Title>
        <Feature flag={FeatureFlags.eyecueAdminUseDashboardLibrary}>
          <Feature.On>
            <Navigation>
              <NavigationItem label='Configured dashboards' name='configured-dashboards' to='all' />
              <NavigationItem label='Dashboard library' name='dashboard-templates' to='templates' />
            </Navigation>
          </Feature.On>
          <Feature.Off>
            <Navigation>
              <NavigationItem label='Configured dashboards' name='configured-dashboards' to='all' />
            </Navigation>
          </Feature.Off>
        </Feature>
      </Header>
      <ContentPanel isLoading={isDashboardsLoading || isTemplatesLoading}>
        <Outlet />
      </ContentPanel>
    </>
  );
};

export default DashboardAdmin;
